//This file is automatically rebuilt by the Cesium build process.
export default "vec4 czm_computeAtmosphereColor(\n\
vec3 positionWC,\n\
vec3 lightDirection,\n\
vec3 rayleighColor,\n\
vec3 mieColor,\n\
float opacity\n\
) {\n\
vec3 cameraToPositionWC = positionWC - czm_viewerPositionWC;\n\
vec3 cameraToPositionWCDirection = normalize(cameraToPositionWC);\n\
float cosAngle = dot(cameraToPositionWCDirection, lightDirection);\n\
float cosAngleSq = cosAngle * cosAngle;\n\
float G = czm_atmosphereMieAnisotropy;\n\
float GSq = G * G;\n\
float rayleighPhase = 3.0 / (50.2654824574) * (1.0 + cosAngleSq);\n\
float miePhase = 3.0 / (25.1327412287) * ((1.0 - GSq) * (cosAngleSq + 1.0)) / (pow(1.0 + GSq - 2.0 * cosAngle * G, 1.5) * (2.0 + GSq));\n\
vec3 rayleigh = rayleighPhase * rayleighColor;\n\
vec3 mie = miePhase * mieColor;\n\
vec3 color = (rayleigh + mie) * czm_atmosphereLightIntensity;\n\
return vec4(color, opacity);\n\
}\n\
";
