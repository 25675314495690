//This file is automatically rebuilt by the Cesium build process.
export default "void atmosphereStage(ProcessedAttributes attributes) {\n\
vec3 lightDirection = czm_getDynamicAtmosphereLightDirection(v_positionWC, czm_atmosphereDynamicLighting);\n\
czm_computeGroundAtmosphereScattering(\n\
v_positionWC,\n\
lightDirection,\n\
v_atmosphereRayleighColor,\n\
v_atmosphereMieColor,\n\
v_atmosphereOpacity\n\
);\n\
}\n\
";
