import {
  Cartesian2,
  Cartesian3,
  DeveloperError,
  Matrix4,
} from "../../Core/cesium/Source/Cesium.js";

var scratchPlaneCenter = new Cartesian3();
var scratchPixelSize = new Cartesian2();
var scratchOldScale = new Cartesian3();
var scratchNetScale = new Cartesian3();

function getPixelSize(frameState, planeCenter, result) {
  var context = frameState.context;
  var cameraCenter = frameState.camera.positionWC;
  var distance = Cartesian3.distance(planeCenter, cameraCenter);
  var width = context.drawingBufferWidth;
  var height = context.drawingBufferHeight;
  return frameState.camera.frustum.getPixelDimensions(
    width,
    height,
    distance,
    frameState.pixelRatio,
    result
  );
}

function getNetScaleFactor(
  pixelSize,
  maximumSizeInMeters,
  metersPerPixel,
  oldScale,
  result
) {
  var scaleX = 1.0;
  var scaleY = 1.0;
  var scaleZ = 1.0;

  var sizeMeters;
  if (pixelSize.x > 0) {
    sizeMeters = pixelSize.x * metersPerPixel.x;
    scaleX = Math.min(sizeMeters, maximumSizeInMeters.x) / oldScale.x;
  }

  if (pixelSize.y > 0) {
    sizeMeters = pixelSize.y * metersPerPixel.y;
    scaleY = Math.min(sizeMeters, maximumSizeInMeters.y) / oldScale.y;
  }

  scaleZ = (scaleX + scaleY) / 2.0;
  return Cartesian3.fromElements(scaleX, scaleY, scaleZ, result);
}

/**
 * Use the Camera frustum to generate a scalingMatrix that scales primitives
 * relative to the number of pixels they should occupy on screen.
 *
 * @param {Cartesian2} pixelSize Desired number of pixels this primitive should occupy after scaling
 * @param {Cartesian2} maximumSizeInMeters Maximum number of meters this primitive should occupy after scaling
 * @param {FrameState} frameState Framestate with active camera / WebGL rendering context to use for scaling
 * @param {Matrix4} modelMatrix Existing modelMatrix to scale
 * @param {Matrix4} result The object into which to store the result
 * @returns {Matrix4} The modified result parameter.
 *
 * @private
 * @ionsdk
 */
export default function getScreenSpaceScalingMatrix(
  pixelSize,
  maximumSizeInMeters,
  frameState,
  modelMatrix,
  result
) {
  //>>includeStart('debug', pragmas.debug);
  if (pixelSize.x < 0 || pixelSize.y < 0) {
    throw new DeveloperError(
      "pixelSize={" +
        pixelSize.x +
        ", " +
        pixelSize.y +
        "}, both components must be >= 0"
    );
  }

  if (maximumSizeInMeters.x < 0 || maximumSizeInMeters.y < 0) {
    throw new DeveloperError(
      "maximumSizeInMeters={" +
        maximumSizeInMeters.x +
        ", " +
        maximumSizeInMeters.y +
        "}, both components must be >= 0"
    );
  }
  //>>includeEnd('debug');

  var planeCenter = Matrix4.getTranslation(modelMatrix, scratchPlaneCenter);
  var metersPerPixel = getPixelSize(frameState, planeCenter, scratchPixelSize);
  var oldScale = Matrix4.getScale(modelMatrix, scratchOldScale);
  var netScale = getNetScaleFactor(
    pixelSize,
    maximumSizeInMeters,
    metersPerPixel,
    oldScale,
    scratchNetScale
  );
  return Matrix4.multiplyByScale(modelMatrix, netScale, result);
}
