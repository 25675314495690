import {
  AxisAlignedBoundingBox,
  BoundingSphere,
  Cartesian2,
  Cartesian3,
  Cartographic,
  CallbackProperty,
  Color,
  CornerType,
  CustomDataSource,
  DeveloperError,
  Matrix3,
  Matrix4,
  Transforms,
} from "../../../Core/cesium/Source/Cesium.js";

import RotationMarker from "./RotationMarker";
import { getCenter, pickCenterOnEllipsoid, projectPointOnSegment } from "./utils";
import TilesetAssetClippingBox from "./TilesetAssetClippingBox";
import SlicerArrows from "./SlicerArrows";

const scratchViewCenter = new Cartesian3();
const scratchMatrix3 = new Matrix3();
const scratchInvTransform = new Matrix4();
const scratchLocalZRotationMatrix = new Matrix4();
const scratchTopLeft = new Cartesian3();
const scratchTopRight = new Cartesian3();
const scratchBottomLeft = new Cartesian3();
const scratchBottomRight = new Cartesian3();
const scratchTransform = new Matrix4();

function moveTwoPositions(position1, position2, moveVector) {
  Cartesian3.add(position1, moveVector, position1);
  Cartesian3.add(position2, moveVector, position2);
}

function extendAtVertical(position, amount, result) {
  const magnitude = Cartesian3.magnitude(position);

  return Cartesian3.multiplyByScalar(position, (magnitude + amount) / magnitude, result);
}

export default class ClippingBox {
  constructor(options) {
    this._viewer = options.viewer;
    this._dataSource = new CustomDataSource("ClippingBox");
    this._viewer.dataSources.add(this._dataSource);

    this._bottomCornersAtLocal = {
      topLeft: new Cartesian3(),
      topRight: new Cartesian3(),
      bottomLeft: new Cartesian3(),
      bottomRight: new Cartesian3()
    };

    this._bottomCorners = {
      topLeft: new Cartesian3(),
      topRight: new Cartesian3(),
      bottomLeft: new Cartesian3(),
      bottomRight: new Cartesian3()
    };

    const imagesRoot = `/glbs`;

    const SLICE_BOX_ARROWS_INSIDE = [
      {
        side: "left",
        oppositeSide: "right",
        uri: `https://s3.amazonaws.com/cdn.s3.consilienceanalytics.com/ca-3d-viewer/sphere.glb`,
        color: Color.LIMEGREEN
      },
      {
        side: "right",
        oppositeSide: "left",
        uri: `https://s3.amazonaws.com/cdn.s3.consilienceanalytics.com/ca-3d-viewer/sphere.glb`,
        color: Color.LIMEGREEN
      },
      {
        side: "back",
        oppositeSide: "front",
        uri: `https://s3.amazonaws.com/cdn.s3.consilienceanalytics.com/ca-3d-viewer/sphere.glb`,
        color: Color.TOMATO
      },
      {
        side: "front",
        oppositeSide: "back",
        uri: `https://s3.amazonaws.com/cdn.s3.consilienceanalytics.com/ca-3d-viewer/sphere.glb`,
        color: Color.TOMATO
      }
    ];

    const SLICE_BOX_ARROWS_OUTSIDE = [
      ...SLICE_BOX_ARROWS_INSIDE,
      {
        side: "down",
        oppositeSide: "up",
        uri: `https://s3.amazonaws.com/cdn.s3.consilienceanalytics.com/ca-3d-viewer/sphere.glb`,
        color: Color.DODGERBLUE
      },
      {
        side: "up",
        oppositeSide: "down",
        uri: `https://s3.amazonaws.com/cdn.s3.consilienceanalytics.com/ca-3d-viewer/sphere.glb`,
        color: Color.DODGERBLUE
      }
    ];

    this._slicerArrows = new SlicerArrows(this.viewer, this.dataSource, {
      moveCallback: (side, moveAmount, moveVector) =>
        this._onSelectedArrowMoved(side, moveAmount, moveVector),
      positionUpdateCallback: (side) => this._arrowPositionUpdateCallback(side),
      arrowsList: SLICE_BOX_ARROWS_OUTSIDE
    });

    this._height = 0;
    this._assetClippingBoxes = [];

    this._slicerArrows.selectedArrowReleased.addEventListener(this._onSelectedArrowReleased.bind(this));

    this._rotationMarker = new RotationMarker({
      scene: this._viewer.scene
    });

    this._rotationMarker.rotated.addEventListener(this._onRotationMarkerRotated.bind(this));
    this._rotationMarker.rotationFinished.addEventListener(this._onRotationMarkerRotationFinished.bind(this));
  }

  get viewer() {
    return this._viewer;
  }

  get dataSource() {
    return this._dataSource;
  }

  get active() {
    return this._tilesetAssetGroup !== undefined;
  }

  reset() {
    console.assert(this._tilesetAssetGroup !== undefined, "error");

    const tilesetAssetGroup = this._tilesetAssetGroup;

    this._clear();
    this.activate(tilesetAssetGroup);
  }

  deactivate() {
    if (this.active) {
      this._clear();
    }
  }

  activate(tilesetAssetGroup) {
    const aabbox = tilesetAssetGroup.getAABoundingBox(new AxisAlignedBoundingBox());
    let center = aabbox.center;

    const maximum = aabbox.maximum;
    const minimum = aabbox.minimum;

    let xDimension = maximum.x - minimum.x;
    let yDimension = maximum.y - minimum.y;
    let zDimension = maximum.z - minimum.z;

    if (tilesetAssetGroup.length === 1) {
      const asset = tilesetAssetGroup.assets[0];
      const dimensions = asset.getDimensions();

      center = asset.tileset.root.boundingVolume.boundingVolume.center;

      xDimension = dimensions.x;
      yDimension = dimensions.y;
      zDimension = dimensions.z;
    }

    const xHalfDimension = xDimension / 2;
    const yHalfDimension = yDimension / 2;
    const zHalfDimension = zDimension / 2;

    const bottomCornersInLocal = this._bottomCornersAtLocal;

    Cartesian3.unpack([-xHalfDimension, -yHalfDimension, -zHalfDimension], 0, bottomCornersInLocal.topLeft);
    Cartesian3.unpack([xHalfDimension, -yHalfDimension, -zHalfDimension], 0, bottomCornersInLocal.topRight);
    Cartesian3.unpack([-xHalfDimension, yHalfDimension, -zHalfDimension], 0, bottomCornersInLocal.bottomLeft);
    Cartesian3.unpack([xHalfDimension, yHalfDimension, -zHalfDimension], 0, bottomCornersInLocal.bottomRight);

    this._transform = Transforms.eastNorthUpToFixedFrame(center, undefined, scratchTransform);

    this._updateBottomCornersInWorld();

    this._height = zDimension;

    this._createBoxEntity();

    tilesetAssetGroup.assets.forEach((asset) => {
      this._assetClippingBoxes.push(
        new TilesetAssetClippingBox({
          asset: asset,
          center: center,
          xDimension: xDimension,
          yDimension: yDimension,
          zDimension: zDimension
        })
      );
    });

    this._rotationMarker.update(this.bottomCorners);

    this._tilesetAssetGroup = tilesetAssetGroup;
  }

  updateClippingPlanes(item) {
    this._height = item.height;
    this._transform = Matrix4.fromArray(item.transform);

    const bottomCorners = this._bottomCorners;
    Cartesian3.clone(new Cartesian3(item.corners.topLeft.x, item.corners.topLeft.y, item.corners.topLeft.z), bottomCorners.topLeft);
    Cartesian3.clone(new Cartesian3(item.corners.topRight.x, item.corners.topRight.y, item.corners.topRight.z), bottomCorners.topRight);
    Cartesian3.clone(new Cartesian3(item.corners.bottomLeft.x, item.corners.bottomLeft.y, item.corners.bottomLeft.z), bottomCorners.bottomLeft);
    Cartesian3.clone(new Cartesian3(item.corners.bottomRight.x, item.corners.bottomRight.y, item.corners.bottomRight.z), bottomCorners.bottomRight);
    this._updateBottomCornersInLocal();

    this._rotationMarker.reset();
    this._rotationMarker.update(this.bottomCorners);

    this._tilesetAssetGroup?.assets.forEach((tilesetAsset) => {
      const tileset = tilesetAsset.tileset;
      tileset.clippingPlanes.modelMatrix = Matrix4.fromArray(item.planes.matrix);
      item.planes.distance.forEach((distance, i) => {
        const plane = tileset.clippingPlanes.get(i);
        if (plane) plane.distance = distance;
      });
      const boundingSphere = BoundingSphere.fromPoints(this.bottomCorners);
      this._assetClippingBoxes.forEach((clippingBox) => {
        clippingBox.setRotationCenter(boundingSphere.center);
        clippingBox._storeClippingPlanesParameters();
        clippingBox._storedClippingPlanesModelMatrixHeading = 0;
      });
    });

    this.enabled = true;
    this.showHideClippingWalls(true);
    this.showEditControls(true);
  }

  showHideClippingWalls(show) {
    this._clippingBoxEntity.show = show;
  }

  showEditControls(show) {
    this._slicerArrows.showHide(show);
    this._rotationMarker.show = show;
  }

  get enabled() {
    // assume this is already activated
    let ret = false;

    this._tilesetAssetGroup?.assets.forEach((tilesetAsset) => {
      ret = tilesetAsset.tileset.clippingPlanes.enabled;
    });

    return ret;
  }

  set enabled(b) {
    // assume this is already activated

    this._tilesetAssetGroup?.assets.forEach((tilesetAsset) => {
      const tileset = tilesetAsset.tileset;
      tileset.clippingPlanes.enabled = b;
    });
  }

  _rotateBottomCorners(angle) {
    const bottomCornersInLocal = this._bottomCornersAtLocal;

    const topLeft = bottomCornersInLocal.topLeft.clone(scratchTopLeft);
    const topRight = bottomCornersInLocal.topRight.clone(scratchTopRight);
    const bottomLeft = bottomCornersInLocal.bottomLeft.clone(scratchBottomLeft);
    const bottomRight = bottomCornersInLocal.bottomRight.clone(scratchBottomRight);

    const rotation = Matrix3.fromRotationZ(angle, scratchMatrix3);
    const rotationMatrix = Matrix4.multiplyByMatrix3(Matrix4.IDENTITY, rotation, scratchLocalZRotationMatrix);

    // rotate local corners
    Matrix4.multiplyByPoint(rotationMatrix, topLeft, topLeft);
    Matrix4.multiplyByPoint(rotationMatrix, topRight, topRight);
    Matrix4.multiplyByPoint(rotationMatrix, bottomLeft, bottomLeft);
    Matrix4.multiplyByPoint(rotationMatrix, bottomRight, bottomRight);

    const bottomCorners = this._bottomCorners;
    // // update world corners
    if (this._rotationMarker._transform) {
      Matrix4.multiplyByPoint(this._transform, topLeft, bottomCorners.topLeft);
      Matrix4.multiplyByPoint(this._transform, topRight, bottomCorners.topRight);
      Matrix4.multiplyByPoint(this._transform, bottomLeft, bottomCorners.bottomLeft);
      Matrix4.multiplyByPoint(this._transform, bottomRight, bottomCorners.bottomRight);
    }
  }

  _onRotationMarkerRotated(angle) {
    this._rotateBottomCorners(this._rotationMarker.accumulatedRotationAngle + angle);

    this._assetClippingBoxes.forEach((clippingBox) => {
      clippingBox.rotate(clippingBox.heading + angle);
    });
    this._slicerArrows._updateArrows();
  }

  _onRotationMarkerRotationFinished() {
    this._assetClippingBoxes.forEach((clippingBox) => {
      clippingBox._storeClippingPlanesParameters();
    });
  }

  get bottomCorners() {
    const bottomCorners = this._bottomCorners;

    return [bottomCorners.topLeft, bottomCorners.topRight, bottomCorners.bottomLeft, bottomCorners.topRight];
  }

  _updateBottomCornersInWorld() {
    const bottomCornersInLocal = this._bottomCornersAtLocal;

    Matrix4.multiplyByPoint(this._transform, bottomCornersInLocal.topLeft, this._bottomCorners.topLeft);
    Matrix4.multiplyByPoint(this._transform, bottomCornersInLocal.topRight, this._bottomCorners.topRight);
    Matrix4.multiplyByPoint(this._transform, bottomCornersInLocal.bottomLeft, this._bottomCorners.bottomLeft);
    Matrix4.multiplyByPoint(this._transform, bottomCornersInLocal.bottomRight, this._bottomCorners.bottomRight);
  }

  _createBoxEntity() {
    const boxPositions = [
      this._bottomCorners.bottomRight,
      this._bottomCorners.bottomLeft,
      this._bottomCorners.topLeft,
      this._bottomCorners.topRight,
      this._bottomCorners.bottomRight
    ];

    const shapeWidth = 0.1;

    this._clippingBoxEntity = this._dataSource.entities.add({
      polylineVolume: {
        positions: new CallbackProperty(() => {
          const ret = [];

          boxPositions.forEach((position) => {
            ret.push(position.clone());
          });

          return ret;
        }, false),
        cornerType: CornerType.MITERED,
        outline: false,
        material: Color.WHITE.withAlpha(0.2),
        shape: new CallbackProperty(() => {
          const startZ = 0;
          const endZ = startZ + this._height;

          return [
            new Cartesian2(0, startZ),
            new Cartesian2(shapeWidth, startZ),
            new Cartesian2(shapeWidth, endZ),
            new Cartesian2(0, endZ)
          ];
        }, false)
      }
    });
  }

  _arrowPositionUpdateCallback(side) {
    if (!this._tilesetAssetGroup) {
      return Cartesian3.ZERO;
    }

    const clippingBoxHeight = this._height;
    const corners = this._bottomCorners;

    if (!clippingBoxHeight || Number.isNaN(clippingBoxHeight)) {
      throw new DeveloperError("invalid clippingBoxHeight");
    }

    if (side === "up" || side === "down") {
      const position = Cartographic.fromCartesian(corners.bottomLeft);

      if (side === "down") {
        const heightOffset = 0.1;
        position.height = position.height - heightOffset + clippingBoxHeight;
      }

      return Cartographic.toCartesian(position);
    }

    let viewCenter = pickCenterOnEllipsoid(this.viewer.scene);

    if (!viewCenter) {
      viewCenter = getCenter(
        [corners.bottomLeft, corners.bottomRight, corners.topLeft, corners.topRight],
        scratchViewCenter
      );
    }

    const heightOffset = clippingBoxHeight + 1;

    const start = 0.5;
    const end = 0.5;

    const bottomRight = Cartographic.fromCartesian(corners.bottomRight);

    switch (side) {
      case "right":
        return projectPointOnSegment(
          viewCenter,
          corners.bottomRight,
          corners.topRight,
          start,
          end,
          bottomRight.height + heightOffset
        );
      case "left": {
        const bottomLeft = Cartographic.fromCartesian(corners.bottomLeft);
        return projectPointOnSegment(
          viewCenter,
          corners.bottomLeft,
          corners.topLeft,
          start,
          end,
          bottomLeft.height + heightOffset
        );
      }
      case "back":
        return projectPointOnSegment(
          viewCenter,
          corners.bottomRight,
          corners.bottomLeft,
          start,
          end,
          bottomRight.height + heightOffset
        );
      case "front":
        return projectPointOnSegment(
          viewCenter,
          corners.topRight,
          corners.topLeft,
          start,
          end,
          bottomRight.height + heightOffset
        );
      default:
        throw new DeveloperError("should not be reached");
    }
  }

  drawBoundingSphere() {
    const boundingSphere = this._tilesetAssetGroup.getBoundingSphere(new BoundingSphere());

    const radii = boundingSphere.radius;

    this._viewer.entities.add({
      position: boundingSphere.center,
      ellipsoid: {
        radii: new Cartesian3(radii, radii, radii),
        outlineColor: Color.YELLOW,
        outline: true,
        fill: false
      }
    });
  }

  getBoundingSphere(result) {
    return this._tilesetAssetGroup.getBoundingSphere(result);
  }

  drawAABoundingBox() {
    const aabbox = this._tilesetAssetGroup.getAABoundingBox(new AxisAlignedBoundingBox());

    const dimensions = Cartesian3.subtract(aabbox.maximum, aabbox.minimum, new Cartesian3());

    this._viewer.entities.add({
      position: aabbox.center,
      box: {
        dimensions: dimensions,
        fill: true,
        outline: true,
        outlineColor: Color.YELLOW,
        material: Color.BLUE.withAlpha(0.2)
      }
    });
  }

  _moveClippingPlanes(clippingPlaneIndex, moveAmount) {
    this._assetClippingBoxes.forEach((clippingBox) => {
      clippingBox.move(clippingPlaneIndex, moveAmount);
    });
  }

  _updateBottomCornersInLocal() {
    const invTransform = Matrix4.inverseTransformation(this._transform, scratchInvTransform);

    const bottomCorners = this._bottomCorners;

    Matrix4.multiplyByPoint(invTransform, bottomCorners.topLeft, this._bottomCornersAtLocal.topLeft);
    Matrix4.multiplyByPoint(invTransform, bottomCorners.topRight, this._bottomCornersAtLocal.topRight);
    Matrix4.multiplyByPoint(invTransform, bottomCorners.bottomLeft, this._bottomCornersAtLocal.bottomLeft);
    Matrix4.multiplyByPoint(invTransform, bottomCorners.bottomRight, this._bottomCornersAtLocal.bottomRight);
  }

  _onSelectedArrowReleased() {
    const positions = this.bottomCorners;
    const boundingSphere = BoundingSphere.fromPoints(positions);

    this._transform = Transforms.eastNorthUpToFixedFrame(boundingSphere.center, undefined, scratchTransform);

    this._assetClippingBoxes.forEach((clippingBox) => {
      clippingBox.setRotationCenter(boundingSphere.center);
      clippingBox._storeClippingPlanesParameters();
    });

    this._updateBottomCornersInLocal();

    this._rotationMarker.reset();
  }

  _onSelectedArrowMoved(side, moveAmount, moveVector) {
    if (isNaN(moveAmount)) {
      return;
    }
    const corners = this._bottomCorners;

    switch (side) {
      case "left": {
        moveTwoPositions(corners.topLeft, corners.bottomLeft, moveVector);
        this._moveClippingPlanes(0, moveAmount);
        break;
      }
      case "right": {
        moveTwoPositions(corners.topRight, corners.bottomRight, moveVector);
        this._moveClippingPlanes(1, moveAmount);
        break;
      }
      case "front": {
        moveTwoPositions(corners.topLeft, corners.topRight, moveVector);
        this._moveClippingPlanes(2, moveAmount);
        break;
      }
      case "back": {
        moveTwoPositions(corners.bottomLeft, corners.bottomRight, moveVector);
        this._moveClippingPlanes(3, moveAmount);
        break;
      }
      case "up": {
        let boxHeight = this._height;

        boxHeight += moveAmount;

        if (boxHeight < 0.1) {
          console.warn("min reached");
          return;
        }

        this._height = boxHeight;

        moveTwoPositions(corners.topLeft, corners.bottomLeft, moveVector);
        moveTwoPositions(corners.topRight, corners.bottomRight, moveVector);

        this._moveClippingPlanes(5, moveAmount);

        break;
      }
      case "down": {
        let boxHeight = this._height;

        boxHeight += moveAmount;

        if (boxHeight < 0.1) {
          console.warn("min reached");
          return;
        }

        this._height = boxHeight;

        this._moveClippingPlanes(4, moveAmount);
        break;
      }
      default: {
        console.error("should not be reached");
      }
    }

    this._rotationMarker.update(this.bottomCorners);
  }

  get aabbox() {
    const bottomCorners = this.bottomCorners;

    const topCorners = [];

    bottomCorners.forEach((position) => {
      topCorners.push(extendAtVertical(position, this._height, new Cartesian3()));
    });

    const positions = bottomCorners.concat(topCorners);

    return AxisAlignedBoundingBox.fromPoints(positions);
  }

  _clear() {
    Matrix4.ZERO.clone(this._transform);

    const bottomCornersAtLocal = this._bottomCornersAtLocal;
    const zero = Cartesian3.ZERO;

    Cartesian3.clone(zero, bottomCornersAtLocal.topLeft);
    Cartesian3.clone(zero, bottomCornersAtLocal.topRight);
    Cartesian3.clone(zero, bottomCornersAtLocal.bottomLeft);
    Cartesian3.clone(zero, bottomCornersAtLocal.bottomRight);

    const bottomCorners = this._bottomCorners;

    Cartesian3.clone(zero, bottomCorners.topLeft);
    Cartesian3.clone(zero, bottomCorners.topRight);
    Cartesian3.clone(zero, bottomCorners.bottomLeft);
    Cartesian3.clone(zero, bottomCorners.bottomRight);

    if (this._clippingBoxEntity) {
      this._dataSource.entities.remove(this._clippingBoxEntity);
      this._clippingBoxEntity = undefined;
    }

    this._height = 0;

    this._assetClippingBoxes.forEach((clippingBox) => {
      clippingBox.destroy();
    });

    this._assetClippingBoxes = [];
    this._rotationMarker.clear();
    this._rotationMarker.reset();

    this._tilesetAssetGroup = undefined;
  }

  get clippingBoxEntity() {
    return this._clippingBoxEntity;
  }
}