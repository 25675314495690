import { Cartesian3, Color, Ellipsoid, HeadingPitchRoll, Matrix4, Plane, Transforms } from "../../Core/cesium/Source/Cesium.js";

import AbstractTool from "./AbstractTool";
import Box from "./Box";
import { calcCenterPoint, EntityTypes, toolIds, utils } from "./common";
import createCoplanarPolygonPrimitive from "./createCoplanarPolygonPrimitive";
import DimensionController from "./DimensionController";
import HeightManipulator from "./HeightManipulator";
import getWorldPosition from "./getWorldPosition";
import Lines from "./Lines";

const pickedPositionScratch = new Cartesian3();
const defaultColor = Color.fromCssColorString("#03a9f4").withAlpha(0.3);
const ToolPhases = {
    firstPoint: 0,
    secondPoint: 1,
    height: 2,
    finished: 3
};

export default class BoxTool extends AbstractTool {
    constructor(cesiumTools) {
        super(toolIds.basic.building, cesiumTools);
        this.phase = ToolPhases.firstPoint;
        this.dimensionsController = new DimensionController(cesiumTools);
        this.dimensionsController.setup();
        cesiumTools.viewer.container.style.cursor = "crosshair";

        this.boxEntity = undefined;
    }

    setup(viewer) {
        super.setup(viewer);
    }

    onDestroy() {
        super.onDestroy();
        this.dimensionsController.clear();
        this.dimensionsController.destroy();
        this.cesiumTools.viewer.container.style.cursor = "default";
    }

    handleClick(event) {
        if (this.phase === ToolPhases.firstPoint) {
            if (this._setFirstPoint(event)) {
                this.phase = ToolPhases.secondPoint;
            }
        } else if (this.phase === ToolPhases.secondPoint) {
            this.dimensionsController.clear();

            const heading = this.viewer.camera.heading;

            const matrix = Transforms.headingPitchRollToFixedFrame(this.point0, new HeadingPitchRoll(heading, 0, 0));
            const inverseMatrix = Matrix4.inverse(matrix, new Matrix4());

            if (this._setSecondPoint(event.position)) {
                this.phase = ToolPhases.height;
                this.cesiumTools.viewer.container.style.cursor = "ns-resize";
                this._removeFootprintRectangle();
                this._createBoxEntity(inverseMatrix);
                this._createVirtualHeightManipulator(event.position);
            }
        } else if (this.phase === ToolPhases.height) {
            this.cesiumTools.entitiesCreated([this.boxEntity]);
            this.phase = ToolPhases.finished;
            const entity = this.boxEntity;
            this.boxEntity = undefined;

            // this.cesiumTools.selectEntity(entity, false);
            window.cesiumTools.deselectAll();
            window.dispatchEvent(new CustomEvent("setMode", { detail: "move" }));
        }

        this.cesiumTools.viewer.scene.requestRender();

        return true;
    }

    handleRightClick(event) {
        this._removeFootprintRectangle();
        if (this.boxEntity) this.boxEntity.removeFromCesium(this.viewer);
        window.cesiumTools.deselectAll();
        window.dispatchEvent(new CustomEvent("setMode", { detail: "move" }));
    }

    mouseMove(event) {
        if (this.phase === ToolPhases.firstPoint) {
            // this.moveShadowPolygonEntity(event);
        } else if (this.phase === ToolPhases.secondPoint) {
            const heading = this.viewer.camera.heading;
            const matrix = Transforms.headingPitchRollToFixedFrame(this.point0, new HeadingPitchRoll(heading, 0, 0));
            const inverse = Matrix4.inverse(matrix, new Matrix4());

            this._setSecondPoint(event.endPosition);
            this._removeFootprintRectangle();
            this._updateXYLabels(matrix, inverse);
            this._createFootprintRectangle(matrix, inverse);
        } else if (this.phase === ToolPhases.height) {
            this._moveUpDown(event);
        }

        return true;
    }

    _setFirstPoint(event) {
        const scene = this.cesiumTools.viewer.scene;
        const ray = this.viewer.camera.getPickRay(event.position);
        let pickedPosition = ray ? getWorldPosition(scene, event.position, pickedPositionScratch) : undefined;

        if (!pickedPosition) {
            pickedPosition = scene.pickPosition(event.position);
        }

        if (pickedPosition) {
            this.point0 = pickedPosition;
            this.basePlane = Plane.fromPointNormal(pickedPosition, Ellipsoid.WGS84.geodeticSurfaceNormal(pickedPosition));
        }

        return pickedPosition;
    }

    _setSecondPoint(screenPosition) {
        if (!this.basePlane) {
            return false;
        }

        const ray = this.cesiumTools.viewer.camera.getPickRay(screenPosition);
        const position = ray ? utils.rayPlane(ray, this.basePlane) : undefined;

        if (!position || Cartesian3.equalsEpsilon(this.point0, position)) {
            return false;
        }

        this.point1 = position;

        return true;
    }

    _createFootprintRectangle(modelMatrix, inverse) {
        const firstLocalPosition = Matrix4.multiplyByPoint(inverse, this.point0, new Cartesian3());
        const secondLocalPosition = Matrix4.multiplyByPoint(inverse, this.point1, new Cartesian3());

        firstLocalPosition.z = 0;
        secondLocalPosition.z = 0;

        const points = (function (e, t) {
            return [e, new Cartesian3(t.x, e.y, e.z), t, new Cartesian3(e.x, t.y, e.z)];
        })(firstLocalPosition, secondLocalPosition);

        const primitive = createCoplanarPolygonPrimitive({
            points: points,
            modelMatrix: modelMatrix,
            color: defaultColor,
            showBorder: true
        });

        if (!primitive) {
            this._boxFootprintRectanglePrimitive  = undefined;
            return;
        }

        const scene = this.cesiumTools.viewer.scene;

        scene.primitives.add(primitive);
        scene.requestRender();

        this._boxFootprintRectanglePrimitive = primitive;
    }

    _removeFootprintRectangle() {
        if (this._boxFootprintRectanglePrimitive) {
            this.cesiumTools.viewer.scene.primitives.remove(this._boxFootprintRectanglePrimitive);
            this._boxFootprintRectanglePrimitive = undefined;
        }
    }

    _updateXYLabels(matrix, inverse) {
        const initialGroundPoints = this.createGroundPoints(inverse);

        let groundPoints = initialGroundPoints;
        let sideIndexes = [1, 2];

        if (Lines.createFromPoints(initialGroundPoints).isClockwise) {
            groundPoints = initialGroundPoints.reverse();
            sideIndexes = [0, 1];
        }

        this.dimensionsController.show({
            height: 0,
            modelMatrix: matrix,
            points: groundPoints,
            sideIndexes: sideIndexes
        });
    }

    createGroundPoints(toLocalMatrix) {
        const n = [this.point0, this.point1].map((i) => Matrix4.multiplyByPoint(toLocalMatrix, i, new Cartesian3()));

        return [n[0], new Cartesian3(n[1].x, n[0].y, n[0].z), n[1], new Cartesian3(n[0].x, n[1].y, n[0].z)];
    }

    _createVirtualHeightManipulator(screenPosition) {
        const referencePoint = this.point1;
        const plane = Plane.fromPointNormal(referencePoint, Ellipsoid.WGS84.geodeticSurfaceNormal(referencePoint));

        this.heightManipulator = new HeightManipulator({
            referencePoint: referencePoint,
            screenPosition: screenPosition,
            plane: plane,
            viewer: this.cesiumTools.viewer,
            heightSnapper: undefined,
            updateHeight: (height) => {
                this._updateEntityDepth(height);
            }
        });
    }

    _updateEntityDepth(depth) {
        if (this.boxEntity) {
            this.boxEntity.depth = depth;
            this.viewer.scene.requestRender();
        }
    }

    _moveUpDown(event) {
        this.heightManipulator?.mouseMove(event);
    }

    _calculateCenterPoint() {
        return calcCenterPoint([this.point0, this.point1]);
    }

    _calculateDimensions(toLocal) {
        const worldPoint0 = Matrix4.multiplyByPoint(toLocal, this.point0, new Cartesian3());
        const worldPoint1 = Matrix4.multiplyByPoint(toLocal, this.point1, new Cartesian3());

        return new Cartesian3(worldPoint1.x - worldPoint0.x, worldPoint1.y - worldPoint0.y, worldPoint1.z - worldPoint0.z);
    }

    _createBoxEntity(toLocal) {
        const position = this._calculateCenterPoint();
        const dimensions = this._calculateDimensions(toLocal);
        dimensions.showDimensions = false;
        dimensions.z = 0;

        if (dimensions.x < 0) {
            dimensions.x = -dimensions.x;
        }

        if (dimensions.y < 0) {
            dimensions.y = -dimensions.y;
        }

        if (dimensions.z < 0) {
            dimensions.z = -dimensions.z;
        }

        let name = this.cesiumTools.findDefaultEntityName(EntityTypes.BOX);
        const number = this.cesiumTools.entitiesList.length + 1;

        name = `${name} #${number}`;

        const heading = this.viewer.camera.heading;

        this.boxEntity = new Box({
            id: this.cesiumTools.entitiesList.reduce((acc, val) => acc > val.id ? acc : val.id, 0) + 1,
            name: name,
            position: position,
            // angle: 0,
            height: 0,
            color: defaultColor,
            opacity: 1,
            // adjustZ: 0,
            viewer: this.viewer,
            // snapToTerrain: true,
            // shadow: true,
            isBox: true,
            dimensions: dimensions,
            orderIndex: -1,
            locked: false,
            visible: true,
            visibleFrom: undefined,
            visibleTo: undefined,
            labelService: this.cesiumTools.labelService,
            heading: heading
        });
    }
}
