import {
    BoxGeometry,
    ColorGeometryInstanceAttribute,
    defaultValue,
    GeometryInstance,
    PerInstanceColorAppearance,
    Primitive,
    ShadowMode
} from "../../Core/cesium/Source/Cesium.js";

export default function createBoxPrimitive(options) {
    const color = options.color;
    const selectable = defaultValue(options.selectable, true);
    const modelMatrix = options.modelMatrix;
    const dimensions = options.dimensions;

    const box = BoxGeometry.fromDimensions({
        dimensions: dimensions,
        vertexFormat: PerInstanceColorAppearance.VERTEX_FORMAT
    });

    const geometry = BoxGeometry.createGeometry(box);

    const appearance = new PerInstanceColorAppearance({
        flat: false,
        closed: false,
        translucent: color.alpha < 1.0
    });

    const geomInstance = new GeometryInstance({
        id: options.id,
        geometry: geometry,
        modelMatrix: modelMatrix,
        attributes: {
            color: ColorGeometryInstanceAttribute.fromColor(color)
        }
    });

    const primitive = new Primitive({
        geometryInstances: geomInstance,
        allowPicking: selectable,
        appearance: appearance,
        shadows: ShadowMode.DISABLED,
        asynchronous: false
    });

    return primitive;
}
