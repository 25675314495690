//This file is automatically rebuilt by the Cesium build process.
export default "uniform vec3 u_ellipsoidRadiiUv;\n\
uniform vec2 u_evoluteScale;\n\
uniform vec3 u_ellipsoidInverseRadiiSquaredUv;\n\
#if defined(ELLIPSOID_HAS_RENDER_BOUNDS_LONGITUDE_MIN_DISCONTINUITY) || defined(ELLIPSOID_HAS_RENDER_BOUNDS_LONGITUDE_MAX_DISCONTINUITY) || defined(ELLIPSOID_HAS_SHAPE_BOUNDS_LONGITUDE_MIN_MAX_REVERSED)\n\
uniform vec3 u_ellipsoidShapeUvLongitudeMinMaxMid;\n\
#endif\n\
#if defined(ELLIPSOID_HAS_SHAPE_BOUNDS_LONGITUDE)\n\
uniform vec2 u_ellipsoidUvToShapeUvLongitude;\n\
#endif\n\
#if defined(ELLIPSOID_HAS_SHAPE_BOUNDS_LATITUDE)\n\
uniform vec2 u_ellipsoidUvToShapeUvLatitude;\n\
#endif\n\
uniform float u_ellipsoidInverseHeightDifferenceUv;\n\
vec3 nearestPointAndRadiusOnEllipse(vec2 pos, vec2 radii) {\n\
vec2 p = abs(pos);\n\
vec2 inverseRadii = 1.0 / radii;\n\
vec2 tTrigs = vec2(0.7071067811865476);\n\
vec2 v = radii * tTrigs;\n\
vec2 evolute = u_evoluteScale * tTrigs * tTrigs * tTrigs;\n\
const int iterations = 3;\n\
for (int i = 0; i < iterations; ++i) {\n\
vec2 q = normalize(p - evolute) * length(v - evolute);\n\
tTrigs = (q + evolute) * inverseRadii;\n\
tTrigs = normalize(clamp(tTrigs, 0.0, 1.0));\n\
v = radii * tTrigs;\n\
evolute = u_evoluteScale * tTrigs * tTrigs * tTrigs;\n\
}\n\
return vec3(v * sign(pos), length(v - evolute));\n\
}\n\
PointJacobianT convertUvToShapeSpaceDerivative(in vec3 positionUv) {\n\
vec3 position = positionUv * 2.0 - 1.0;\n\
position = position * u_ellipsoidRadiiUv;\n\
float longitude = atan(position.y, position.x);\n\
vec3 east = normalize(vec3(-position.y, position.x, 0.0));\n\
float distanceFromZAxis = length(position.xy);\n\
vec2 posEllipse = vec2(distanceFromZAxis, position.z);\n\
vec3 surfacePointAndRadius = nearestPointAndRadiusOnEllipse(posEllipse, u_ellipsoidRadiiUv.xz);\n\
vec2 surfacePoint = surfacePointAndRadius.xy;\n\
vec2 normal2d = normalize(surfacePoint * u_ellipsoidInverseRadiiSquaredUv.xz);\n\
float latitude = atan(normal2d.y, normal2d.x);\n\
vec3 north = vec3(-normal2d.y * normalize(position.xy), abs(normal2d.x));\n\
float heightSign = length(posEllipse) < length(surfacePoint) ? -1.0 : 1.0;\n\
float height = heightSign * length(posEllipse - surfacePoint);\n\
vec3 up = normalize(cross(east, north));\n\
vec3 point = vec3(longitude, latitude, height);\n\
mat3 jacobianT = mat3(east / distanceFromZAxis, north / (surfacePointAndRadius.z + height), up);\n\
return PointJacobianT(point, jacobianT);\n\
}\n\
vec3 convertShapeToShapeUvSpace(in vec3 positionShape) {\n\
float longitude = (positionShape.x + czm_pi) / czm_twoPi;\n\
#if defined(ELLIPSOID_HAS_SHAPE_BOUNDS_LONGITUDE_MIN_MAX_REVERSED)\n\
longitude += float(longitude < u_ellipsoidShapeUvLongitudeMinMaxMid.z);\n\
#endif\n\
#if defined(ELLIPSOID_HAS_RENDER_BOUNDS_LONGITUDE_MIN_DISCONTINUITY)\n\
longitude = longitude > u_ellipsoidShapeUvLongitudeMinMaxMid.z ? u_ellipsoidShapeUvLongitudeMinMaxMid.x : longitude;\n\
#endif\n\
#if defined(ELLIPSOID_HAS_RENDER_BOUNDS_LONGITUDE_MAX_DISCONTINUITY)\n\
longitude = longitude < u_ellipsoidShapeUvLongitudeMinMaxMid.z ? u_ellipsoidShapeUvLongitudeMinMaxMid.y : longitude;\n\
#endif\n\
#if defined(ELLIPSOID_HAS_SHAPE_BOUNDS_LONGITUDE)\n\
longitude = longitude * u_ellipsoidUvToShapeUvLongitude.x + u_ellipsoidUvToShapeUvLongitude.y;\n\
#endif\n\
float latitude = (positionShape.y + czm_piOverTwo) / czm_pi;\n\
#if defined(ELLIPSOID_HAS_SHAPE_BOUNDS_LATITUDE)\n\
latitude = latitude * u_ellipsoidUvToShapeUvLatitude.x + u_ellipsoidUvToShapeUvLatitude.y;\n\
#endif\n\
float height = 1.0 + positionShape.z * u_ellipsoidInverseHeightDifferenceUv;\n\
return vec3(longitude, latitude, height);\n\
}\n\
PointJacobianT convertUvToShapeUvSpaceDerivative(in vec3 positionUv) {\n\
PointJacobianT pointJacobian = convertUvToShapeSpaceDerivative(positionUv);\n\
pointJacobian.point = convertShapeToShapeUvSpace(pointJacobian.point);\n\
return pointJacobian;\n\
}\n\
vec3 scaleShapeUvToShapeSpace(in vec3 shapeUv) {\n\
float longitude = shapeUv.x * czm_twoPi;\n\
#if defined (ELLIPSOID_HAS_SHAPE_BOUNDS_LONGITUDE)\n\
longitude /= u_ellipsoidUvToShapeUvLongitude.x;\n\
#endif\n\
float latitude = shapeUv.y * czm_pi;\n\
#if defined(ELLIPSOID_HAS_SHAPE_BOUNDS_LATITUDE)\n\
latitude /= u_ellipsoidUvToShapeUvLatitude.x;\n\
#endif\n\
float height = shapeUv.z / u_ellipsoidInverseHeightDifferenceUv;\n\
return vec3(longitude, latitude, height);\n\
}\n\
";
