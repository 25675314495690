import {
  defined,
  DeveloperError,
  wrapFunction,
} from "../../../Core/cesium/Source/Cesium.js";

import IsolateButton from "../IsolateButton/IsolateButton.js";

/**
 * A mixin which adds the Isolate widget to the Viewer widget.
 * Rather than being called directly, this function is normally passed as
 * a parameter to {@link Viewer#extend}, as shown in the example below.
 * @function
 *
 * @param {Viewer} viewer The viewer instance.
 * @param {Object} [options] An object with the following properties:
 * @param {String} [options.locale] The {@link https://tools.ietf.org/html/rfc5646|BCP 47 language tag} string customizing language-sensitive number formatting. If <code>undefined</code>, the runtime's default locale is used. See the {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#Locale_identification_and_negotiation|Intl page on MDN}
 *
 * @exception {DeveloperError} viewer is required.
 *
 * @example
 * var viewer = new Cesium.Viewer('cesiumContainer');
 * viewer.extend(Cesium.viewerSettingsButtonMixin);
 */
function viewerIsolateButtonMixin(viewer, options) {
  if (!defined(viewer)) {
    throw new DeveloperError("viewer is required.");
  }
  const scene = viewer.scene;

  const cesiumIsolateContainer = document.createElement("div");
  cesiumIsolateContainer.className = "cesium-viewer-isolateContainer";
  viewer._toolbar.insertBefore(
    cesiumIsolateContainer,
    viewer._toolbar.lastChild
  );
  const isolateButton = new IsolateButton(cesiumIsolateContainer, scene);

  viewer.destroy = wrapFunction(viewer, viewer.destroy, function () {
    isolateButton.destroy();
  });

  Object.defineProperties(viewer, {
    isolateButton: {
      get: function () {
        return isolateButton;
      },
    },
  });
}
export default viewerIsolateButtonMixin;
