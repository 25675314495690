//This file is automatically rebuilt by the Cesium build process.
export default "vec2 nearestPointOnEllipseFast(vec2 pos, vec2 radii) {\n\
vec2 p = abs(pos);\n\
vec2 inverseRadii = 1.0 / radii;\n\
vec2 evoluteScale = (radii.x * radii.x - radii.y * radii.y) * vec2(1.0, -1.0) * inverseRadii;\n\
vec2 tTrigs = vec2(0.70710678118);\n\
vec2 v = radii * tTrigs;\n\
vec2 evolute = evoluteScale * tTrigs * tTrigs * tTrigs;\n\
vec2 q = normalize(p - evolute) * length(v - evolute);\n\
tTrigs = (q + evolute) * inverseRadii;\n\
tTrigs = normalize(clamp(tTrigs, 0.0, 1.0));\n\
v = radii * tTrigs;\n\
return v * sign(pos);\n\
}\n\
vec3 computeEllipsoidPositionWC(vec3 positionMC) {\n\
vec3 positionWC = (czm_model * vec4(positionMC, 1.0)).xyz;\n\
vec2 positionEllipse = vec2(length(positionWC.xy), positionWC.z);\n\
vec2 nearestPoint = nearestPointOnEllipseFast(positionEllipse, czm_ellipsoidRadii.xz);\n\
return vec3(nearestPoint.x * normalize(positionWC.xy), nearestPoint.y);\n\
}\n\
void applyFog(inout vec4 color, vec4 groundAtmosphereColor, vec3 lightDirection, float distanceToCamera) {\n\
vec3 fogColor = groundAtmosphereColor.rgb;\n\
const float NONE = 0.0;\n\
if (czm_atmosphereDynamicLighting != NONE) {\n\
float darken = clamp(dot(normalize(czm_viewerPositionWC), lightDirection), czm_fogMinimumBrightness, 1.0);\n\
fogColor *= darken;\n\
}\n\
#ifndef HDR\n\
fogColor.rgb = czm_acesTonemapping(fogColor.rgb);\n\
fogColor.rgb = czm_inverseGamma(fogColor.rgb);\n\
#endif\n\
const float fogModifier = 0.15;\n\
vec3 withFog = czm_fog(distanceToCamera, color.rgb, fogColor, fogModifier);\n\
color = vec4(withFog, color.a);\n\
}\n\
void atmosphereStage(inout vec4 color, in ProcessedAttributes attributes) {\n\
vec3 rayleighColor;\n\
vec3 mieColor;\n\
float opacity;\n\
vec3 positionWC;\n\
vec3 lightDirection;\n\
if (false) {\n\
positionWC = computeEllipsoidPositionWC(attributes.positionMC);\n\
lightDirection = czm_getDynamicAtmosphereLightDirection(positionWC, czm_atmosphereDynamicLighting);\n\
czm_computeGroundAtmosphereScattering(\n\
positionWC,\n\
lightDirection,\n\
rayleighColor,\n\
mieColor,\n\
opacity\n\
);\n\
} else {\n\
positionWC = attributes.positionWC;\n\
lightDirection = czm_getDynamicAtmosphereLightDirection(positionWC, czm_atmosphereDynamicLighting);\n\
rayleighColor = v_atmosphereRayleighColor;\n\
mieColor = v_atmosphereMieColor;\n\
opacity = v_atmosphereOpacity;\n\
}\n\
const bool ignoreBlackPixels = true;\n\
rayleighColor = czm_applyHSBShift(rayleighColor, czm_atmosphereHsbShift, ignoreBlackPixels);\n\
mieColor = czm_applyHSBShift(mieColor, czm_atmosphereHsbShift, ignoreBlackPixels);\n\
vec4 groundAtmosphereColor = czm_computeAtmosphereColor(positionWC, lightDirection, rayleighColor, mieColor, opacity);\n\
if (u_isInFog) {\n\
float distanceToCamera = length(attributes.positionEC);\n\
applyFog(color, groundAtmosphereColor, lightDirection, distanceToCamera);\n\
} else {\n\
}\n\
}\n\
";
