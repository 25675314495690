import {
  defaultValue,
  destroyObject,
  Check,
  getElement,
  knockout,
} from "../../../Core/cesium/Source/Cesium.js";

import MeasureViewModel from "./MeasureViewModel.js";
import createDomNode from "../createDomNode.js";
//  <div class="cesium-measure-button" data-bind="click: function($data) { $parent.selectedMeasurement = $data; }, attr: {title: type, id: id}, css: {active: $data === $parent.selectedMeasurement}, html: thumbnail"></div>\n\
//@TODO return empty html
let html = '<div style="display: flex">\n\
    <div class="cesium-measure-toolbar" data-bind="css: {expanded: true}">\n\
                   <!-- ko foreach: measurements -->\n\
                   <div class="cesium-measure-button" data-bind="click: function($data) { $parent.selectedMeasurement = $data;}, attr: {title: type, id: id}, html: thumbnail"></div>\n\
                   <!-- /ko -->\n\
               </div>\n\
               ';

const exportMeasurementHtml = '';

/**
 * <span style="display: block; text-align: center;">
 * <img src="Images/Measure.png" width="348" height="44" alt="" />
 * <br />Measure toolbar expanded.
 * </span>
 * <br /><br />
 * Measure is a widget that allows users to make ephemeral measurements by clicking on the globe surface and on Cesium3DTiles and glTF models.
 *
 * <p>
 * Measurement types include:
 * <ul>
 * <li>
 * Area: Computes the area of an arbitrary polygon.  Note that the polygon area does not take into account the contours of terrain.
 * </li><li>
 * Distance: Computes a linear distance between two points.  Note that measurements on the earth do not conform to terrain.
 * </li><li>
 * Component Distance: Computes a linear distance between two points, with horizontal and vertical components and the angle of the line.  Note that measurements on the earth do not conform to terrain.
 * </li><li>
 * Height: Computes a linear distance between a point in space and the terrain below that point.  This value will always be 0 if activated in 2D mode.
 * </li><li>
 * Horizontal: Computes a linear distance between two points at the same height relative to the the WGS84 Ellipsoid.
 * </li><li>
 * Point: Displays the longitude and latitude coordinates and the height above terrain at a specified point in space.
 * </li><li>
 * Vertical: Computes a linear distance between two points with the same longitude/latitude but different heights.  This value will always be 0 if activated in 2D mode.
 * </li>
 * </ul>
 * </p>
 *
 * Note that drawing measurements on 3D tiles and models may not be supported by all browsers.  Check the tilesetMeasurementSupported to see
 * if it is supported.
 *
 *
 * @see AreaMeasurement
 * @see DistanceMeasurement
 * @see HeightMeasurement
 * @see HorizontalMeasurement
 * @see PointMeasurement
 * @see VerticalMeasurement
 *
 * @alias Measure
 * @constructor
 *
 * @param {Object} options An object with the following properties
 * @param {String|Element} options.container The container for the widget
 * @param {Scene} options.scene The scene
 * @param {MeasureUnits} [options.units] The default unit of measurement
 * @param {Boolean} [options.hideExportMeasurementButton] If true the measurement export button will be hidden
 * @param {String} [options.locale] The {@link https://tools.ietf.org/html/rfc5646|BCP 47 language tag} string customizing language-sensitive number formatting. If <code>undefined</code>, the runtime's default locale is used. See the {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#Locale_identification_and_negotiation|Intl page on MDN}
 * @param {PrimitiveCollection} [options.primitives] A collection in which to store the measurement primitives
 *
 * @demo <a href="/Apps/Sandcastle/index.html?src=Measure%20Widget.html">Cesium Sandcastle Measure Widget Demo</a>
 *
 * @example
 * // In HTML head, include a link to the Measure.css stylesheet,
 * // and in the body, include: <div id="measureContainer"></div>
 * // Note: This code assumes you already have a Scene instance.
 *
 * var measureWidget = new Cesium.Measure({
 *      container : 'measureContainer',
 *      scene : scene,
 *      units : new Cesium.MeasureUnits({
 *          distanceUnits : Cesium.DistanceUnits.METERS,
 *          areaUnits : Cesium.AreaUnits.SQUARE_METERS,
 *          volumeUnits : Cesium.VolumeUnits.CUBIC_FEET,
 *          angleUnits : Cesium.AngleUnits.DEGREES,
 *          slopeUnits : Cesium.AngleUnits.GRADE
 *      })
 * });
 */
function Measure(options) {
  options = defaultValue(options, defaultValue.EMPTY_OBJECT);
  let container = options.container;

  //>>includeStart('debug', pragmas.debug);
  Check.defined("options.container", container);
  Check.defined("options.scene", options.scene);
  //>>includeEnd('debug');

  let element = createDomNode(html);

  container = getElement(container);
  element = container.appendChild(element);
  const viewModel = new MeasureViewModel(options);
  knockout.cleanNode(container);
  knockout.applyBindings(viewModel, container);

  this._viewModel = viewModel;
  this._container = container;
  this._element = element;
}

Object.defineProperties(Measure.prototype, {
  /**
   * Gets the parent container.
   * @memberof Measure.prototype
   *
   * @type {Element}
   * @readonly
   */
  container: {
    get: function () {
      return this._container;
    },
  },

  /**
   * Gets the view model.
   * @memberof Measure.prototype
   *
   * @type {MeasureViewModel}
   * @readonly
   */
  viewModel: {
    get: function () {
      return this._viewModel;
    },
  },

  /**
   * Gets whether drawing a measurement on a Cesium3DTileset or Model is supported
   * @memberof Measure.prototype
   *
   * @type {Boolean}
   * @readonly
   */
  tilesetMeasurementSupported: {
    get: function () {
      return this._scene.pickPositionSupported;
    },
  },
});

/**
 * @returns {Boolean} true if the object has been destroyed, false otherwise.
 */
Measure.prototype.isDestroyed = function () {
  return false;
};

/**
 * Destroys the widget.  Should be called if permanently
 * removing the widget from layout.
 */
Measure.prototype.destroy = function () {
  this._viewModel.destroy();
  knockout.cleanNode(this._container);
  this._container.removeChild(this._element);

  return destroyObject(this);
};

export default Measure;
