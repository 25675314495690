//This file is automatically rebuilt by the Cesium build process.
export default "vec3 czm_getDynamicAtmosphereLightDirection(vec3 positionWC, float lightEnum) {\n\
const float NONE = 0.0;\n\
const float SCENE_LIGHT = 1.0;\n\
const float SUNLIGHT = 2.0;\n\
vec3 lightDirection =\n\
positionWC * float(lightEnum == NONE) +\n\
czm_lightDirectionWC * float(lightEnum == SCENE_LIGHT) +\n\
czm_sunDirectionWC * float(lightEnum == SUNLIGHT);\n\
return normalize(lightDirection);\n\
}\n\
";
