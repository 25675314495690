//This file is automatically rebuilt by the Cesium build process.
export default "in vec3 v_outerPositionWC;\n\
uniform vec3 u_hsbShift;\n\
#ifndef PER_FRAGMENT_ATMOSPHERE\n\
in vec3 v_mieColor;\n\
in vec3 v_rayleighColor;\n\
in float v_opacity;\n\
in float v_translucent;\n\
#endif\n\
void main (void)\n\
{\n\
float lightEnum = u_radiiAndDynamicAtmosphereColor.z;\n\
vec3 lightDirection = czm_getDynamicAtmosphereLightDirection(v_outerPositionWC, lightEnum);\n\
vec3 mieColor;\n\
vec3 rayleighColor;\n\
float opacity;\n\
float translucent;\n\
#ifdef PER_FRAGMENT_ATMOSPHERE\n\
computeAtmosphereScattering(\n\
v_outerPositionWC,\n\
lightDirection,\n\
rayleighColor,\n\
mieColor,\n\
opacity,\n\
translucent\n\
);\n\
#else\n\
mieColor = v_mieColor;\n\
rayleighColor = v_rayleighColor;\n\
opacity = v_opacity;\n\
translucent = v_translucent;\n\
#endif\n\
vec4 color = computeAtmosphereColor(v_outerPositionWC, lightDirection, rayleighColor, mieColor, opacity);\n\
#ifndef HDR\n\
color.rgb = czm_acesTonemapping(color.rgb);\n\
color.rgb = czm_inverseGamma(color.rgb);\n\
#endif\n\
#ifdef COLOR_CORRECT\n\
const bool ignoreBlackPixels = true;\n\
color.rgb = czm_applyHSBShift(color.rgb, u_hsbShift, ignoreBlackPixels);\n\
#endif\n\
if (translucent == 0.0) {\n\
color.a = mix(color.b, 1.0, color.a) * smoothstep(0.0, 1.0, czm_morphTime);\n\
}\n\
out_FragColor = color;\n\
}\n\
";
