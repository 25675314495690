//This file is automatically rebuilt by the Cesium build process.
export default "#if defined(BOX_HAS_SHAPE_BOUNDS)\n\
uniform vec3 u_boxUvToShapeUvScale;\n\
uniform vec3 u_boxUvToShapeUvTranslate;\n\
#endif\n\
PointJacobianT convertUvToShapeSpaceDerivative(in vec3 positionUv) {\n\
return PointJacobianT(positionUv, mat3(0.5));\n\
}\n\
vec3 convertShapeToShapeUvSpace(in vec3 positionShape) {\n\
#if defined(BOX_HAS_SHAPE_BOUNDS)\n\
return positionShape * u_boxUvToShapeUvScale + u_boxUvToShapeUvTranslate;\n\
#else\n\
return positionShape;\n\
#endif\n\
}\n\
PointJacobianT convertUvToShapeUvSpaceDerivative(in vec3 positionUv) {\n\
PointJacobianT pointJacobian = convertUvToShapeSpaceDerivative(positionUv);\n\
pointJacobian.point = convertShapeToShapeUvSpace(pointJacobian.point);\n\
return pointJacobian;\n\
}\n\
vec3 convertShapeUvToUvSpace(in vec3 shapeUv) {\n\
#if defined(BOX_HAS_SHAPE_BOUNDS)\n\
return (shapeUv - u_boxUvToShapeUvTranslate) / u_boxUvToShapeUvScale;\n\
#else\n\
return shapeUv;\n\
#endif\n\
}\n\
vec3 scaleShapeUvToShapeSpace(in vec3 shapeUv) {\n\
#if defined(BOX_HAS_SHAPE_BOUNDS)\n\
return shapeUv / u_boxUvToShapeUvScale;\n\
#else\n\
return shapeUv;\n\
#endif\n\
}\n\
";
