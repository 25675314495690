import {
  defined,
  DeveloperError,
} from "../../../Core/cesium/Source/Cesium.js";
import CesiumTools from "../CesiumTools.js";
import CesiumEventsHandler from "../CesiumEventsHandler.js";
import jQuery from "jquery";

/**
 * A mixin which adds primitive placement functionality
 * Rather than being called directly, this function is normally passed as
 * a parameter to {@link Viewer#extend}, as shown in the example below.
 * @function
 *
 * @param {Viewer} viewer The viewer instance.
 * @param {Object} [options] An object with the following properties:
 * @param {String} [options.locale] The {@link https://tools.ietf.org/html/rfc5646|BCP 47 language tag} string customizing language-sensitive number formatting. If <code>undefined</code>, the runtime's default locale is used. See the {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#Locale_identification_and_negotiation|Intl page on MDN}
 *
 * @exception {DeveloperError} viewer is required.
 *
 * @example
 * var viewer = new Cesium.Viewer('cesiumContainer');
 * viewer.extend(Cesium.viewerSkyboxDestroyMixin);
 */
function viewerPlacePrimitiveMixin(viewer, options) {

  if (!defined(viewer)) {
    throw new DeveloperError("viewer is required.");
  }

  const cesiumTools = new CesiumTools();

  window.cesiumTools = cesiumTools;

  cesiumTools.setup({
    viewer: viewer,
    cesiumEventHandler: new CesiumEventsHandler(viewer)
  });

  cesiumTools.evtEntitiesCreated.addEventListener((entities) => {
    window.dispatchEvent(new CustomEvent("newPrimitive", { detail: entities[0] }));
  });
  cesiumTools.updatedEntities.addEventListener((entities) => {
    window.dispatchEvent(new CustomEvent("updatePrimitive", { detail: entities[0] }));
  });
}
export default viewerPlacePrimitiveMixin;
