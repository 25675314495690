//This file is automatically rebuilt by the Cesium build process.
export default "#if defined(ELLIPSOID_HAS_RENDER_BOUNDS_LONGITUDE)\n\
uniform vec2 u_ellipsoidRenderLongitudeMinMax;\n\
#endif\n\
uniform float u_eccentricitySquared;\n\
uniform vec2 u_ellipsoidRenderLatitudeSinMinMax;\n\
uniform vec2 u_clipMinMaxHeight;\n\
RayShapeIntersection intersectZPlane(in Ray ray, in float z) {\n\
float t = -ray.pos.z / ray.dir.z;\n\
bool startsOutside = sign(ray.pos.z) == sign(z);\n\
bool entry = (t >= 0.0) != startsOutside;\n\
vec4 intersect = vec4(0.0, 0.0, z, t);\n\
vec4 farSide = vec4(normalize(ray.dir), INF_HIT);\n\
if (entry) {\n\
return RayShapeIntersection(intersect, farSide);\n\
} else {\n\
return RayShapeIntersection(-1.0 * farSide, intersect);\n\
}\n\
}\n\
RayShapeIntersection intersectHeight(in Ray ray, in float relativeHeight, in bool convex)\n\
{\n\
vec3 radiiCorrection = u_ellipsoidRadiiUv / (u_ellipsoidRadiiUv + relativeHeight);\n\
vec3 position = ray.pos * radiiCorrection;\n\
vec3 direction = ray.dir * radiiCorrection;\n\
float a = dot(direction, direction);\n\
float b = dot(direction, position);\n\
float c = dot(position, position) - 1.0;\n\
float determinant = b * b - a * c;\n\
if (determinant < 0.0) {\n\
vec4 miss = vec4(normalize(direction), NO_HIT);\n\
return RayShapeIntersection(miss, miss);\n\
}\n\
determinant = sqrt(determinant);\n\
float signB = b < 0.0 ? -1.0 : 1.0;\n\
float t1 = (-b - signB * determinant) / a;\n\
float t2 = c / (a * t1);\n\
float tmin = min(t1, t2);\n\
float tmax = max(t1, t2);\n\
float directionScale = convex ? 1.0 : -1.0;\n\
vec3 d1 = directionScale * normalize(position + tmin * direction);\n\
vec3 d2 = directionScale * normalize(position + tmax * direction);\n\
return RayShapeIntersection(vec4(d1, tmin), vec4(d2, tmax));\n\
}\n\
vec2 intersectDoubleEndedCone(in Ray ray, in float cosSqrHalfAngle)\n\
{\n\
vec3 o = ray.pos;\n\
vec3 d = ray.dir;\n\
float sinSqrHalfAngle = 1.0 - cosSqrHalfAngle;\n\
float aSin = d.z * d.z * sinSqrHalfAngle;\n\
float aCos = -dot(d.xy, d.xy) * cosSqrHalfAngle;\n\
float a = aSin + aCos;\n\
float bSin = d.z * o.z * sinSqrHalfAngle;\n\
float bCos = -dot(o.xy, d.xy) * cosSqrHalfAngle;\n\
float b = bSin + bCos;\n\
float cSin = o.z * o.z * sinSqrHalfAngle;\n\
float cCos = -dot(o.xy, o.xy) * cosSqrHalfAngle;\n\
float c = cSin + cCos;\n\
float determinant = 2.0 * bSin * bCos + bCos * bCos - aSin * cCos - aCos * cSin - aCos * cCos;\n\
if (determinant < 0.0) {\n\
return vec2(NO_HIT);\n\
} else if (a == 0.0) {\n\
return (b == 0.0)\n\
? vec2(NO_HIT)\n\
: vec2(-0.5 * c / b, NO_HIT);\n\
}\n\
determinant = sqrt(determinant);\n\
float signB = b < 0.0 ? -1.0 : 1.0;\n\
float t1 = (-b - signB * determinant) / a;\n\
float t2 = c / (a * t1);\n\
float tmin = min(t1, t2);\n\
float tmax = max(t1, t2);\n\
return vec2(tmin, tmax);\n\
}\n\
vec3 getConeNormal(in vec3 p, in bool convex) {\n\
vec2 radial = -abs(p.z) * normalize(p.xy);\n\
float zSign = (p.z < 0.0) ? -1.0 : 1.0;\n\
float z = length(p.xy) * zSign;\n\
float flip = (convex) ? -1.0 : 1.0;\n\
return normalize(vec3(radial, z) * flip);\n\
}\n\
float getLatitudeConeShift(in float sinLatitude) {\n\
float x2 = u_eccentricitySquared * sinLatitude * sinLatitude;\n\
float primeVerticalRadius = inversesqrt(1.0 - x2);\n\
return primeVerticalRadius * u_eccentricitySquared * sinLatitude;\n\
}\n\
void intersectFlippedCone(in Ray ray, in float cosHalfAngle, out RayShapeIntersection intersections[2]) {\n\
ray.pos = ray.pos * u_ellipsoidRadiiUv;\n\
ray.dir = ray.dir * u_ellipsoidRadiiUv;\n\
ray.pos.z += getLatitudeConeShift(cosHalfAngle);\n\
float cosSqrHalfAngle = cosHalfAngle * cosHalfAngle;\n\
vec2 intersect = intersectDoubleEndedCone(ray, cosSqrHalfAngle);\n\
vec4 miss = vec4(normalize(ray.dir), NO_HIT);\n\
vec4 farSide = vec4(normalize(ray.dir), INF_HIT);\n\
intersections[0].entry = -1.0 * farSide;\n\
intersections[0].exit = farSide;\n\
intersections[1].entry = miss;\n\
intersections[1].exit = miss;\n\
if (intersect.x == NO_HIT) {\n\
return;\n\
}\n\
float tmin = intersect.x;\n\
float tmax = intersect.y;\n\
vec3 p0 = ray.pos + tmin * ray.dir;\n\
vec3 p1 = ray.pos + tmax * ray.dir;\n\
vec4 intersect0 = vec4(getConeNormal(p0, true), tmin);\n\
vec4 intersect1 = vec4(getConeNormal(p1, true), tmax);\n\
bool p0InShadowCone = sign(p0.z) != sign(cosHalfAngle);\n\
bool p1InShadowCone = sign(p1.z) != sign(cosHalfAngle);\n\
if (p0InShadowCone && p1InShadowCone) {\n\
} else if (p0InShadowCone) {\n\
intersections[0].exit = intersect1;\n\
} else if (p1InShadowCone) {\n\
intersections[0].entry = intersect0;\n\
} else {\n\
intersections[0].exit = intersect0;\n\
intersections[1].entry = intersect1;\n\
intersections[1].exit = farSide;\n\
}\n\
}\n\
RayShapeIntersection intersectRegularCone(in Ray ray, in float cosHalfAngle, in bool convex) {\n\
ray.pos = ray.pos * u_ellipsoidRadiiUv;\n\
ray.dir = ray.dir * u_ellipsoidRadiiUv;\n\
ray.pos.z += getLatitudeConeShift(cosHalfAngle);\n\
float cosSqrHalfAngle = cosHalfAngle * cosHalfAngle;\n\
vec2 intersect = intersectDoubleEndedCone(ray, cosSqrHalfAngle);\n\
vec4 miss = vec4(normalize(ray.dir), NO_HIT);\n\
vec4 farSide = vec4(normalize(ray.dir), INF_HIT);\n\
if (intersect.x == NO_HIT) {\n\
return RayShapeIntersection(miss, miss);\n\
}\n\
float tmin = intersect.x;\n\
float tmax = intersect.y;\n\
vec3 p0 = ray.pos + tmin * ray.dir;\n\
vec3 p1 = ray.pos + tmax * ray.dir;\n\
vec4 intersect0 = vec4(getConeNormal(p0, convex), tmin);\n\
vec4 intersect1 = vec4(getConeNormal(p1, convex), tmax);\n\
bool p0InShadowCone = sign(p0.z) != sign(cosHalfAngle);\n\
bool p1InShadowCone = sign(p1.z) != sign(cosHalfAngle);\n\
if (p0InShadowCone && p1InShadowCone) {\n\
return RayShapeIntersection(miss, miss);\n\
} else if (p0InShadowCone) {\n\
return RayShapeIntersection(intersect1, farSide);\n\
} else if (p1InShadowCone) {\n\
return RayShapeIntersection(-1.0 * farSide, intersect0);\n\
} else {\n\
return RayShapeIntersection(intersect0, intersect1);\n\
}\n\
}\n\
void intersectShape(in Ray ray, inout Intersections ix) {\n\
ray.pos = ray.pos * 2.0 - 1.0;\n\
ray.dir *= 2.0;\n\
RayShapeIntersection outerIntersect = intersectHeight(ray, u_clipMinMaxHeight.y, true);\n\
setShapeIntersection(ix, ELLIPSOID_INTERSECTION_INDEX_HEIGHT_MAX, outerIntersect);\n\
if (outerIntersect.entry.w == NO_HIT) {\n\
return;\n\
}\n\
RayShapeIntersection innerIntersect = intersectHeight(ray, u_clipMinMaxHeight.x, false);\n\
if (innerIntersect.entry.w == NO_HIT) {\n\
setShapeIntersection(ix, ELLIPSOID_INTERSECTION_INDEX_HEIGHT_MIN, innerIntersect);\n\
} else {\n\
innerIntersect.entry.w = max(innerIntersect.entry.w, outerIntersect.entry.w);\n\
innerIntersect.exit.w = min(innerIntersect.exit.w, outerIntersect.exit.w);\n\
setSurfaceIntersection(ix, 0, outerIntersect.entry, true, true);\n\
setSurfaceIntersection(ix, 1, innerIntersect.entry, false, true);\n\
setSurfaceIntersection(ix, 2, innerIntersect.exit, false, false);\n\
setSurfaceIntersection(ix, 3, outerIntersect.exit, true, false);\n\
}\n\
#if defined(ELLIPSOID_HAS_RENDER_BOUNDS_LATITUDE_MIN_UNDER_HALF)\n\
RayShapeIntersection bottomConeIntersection = intersectRegularCone(ray, u_ellipsoidRenderLatitudeSinMinMax.x, false);\n\
setShapeIntersection(ix, ELLIPSOID_INTERSECTION_INDEX_LATITUDE_MIN, bottomConeIntersection);\n\
#elif defined(ELLIPSOID_HAS_RENDER_BOUNDS_LATITUDE_MIN_EQUAL_HALF)\n\
RayShapeIntersection bottomConeIntersection = intersectZPlane(ray, -1.0);\n\
setShapeIntersection(ix, ELLIPSOID_INTERSECTION_INDEX_LATITUDE_MIN, bottomConeIntersection);\n\
#elif defined(ELLIPSOID_HAS_RENDER_BOUNDS_LATITUDE_MIN_OVER_HALF)\n\
RayShapeIntersection bottomConeIntersections[2];\n\
intersectFlippedCone(ray, u_ellipsoidRenderLatitudeSinMinMax.x, bottomConeIntersections);\n\
setShapeIntersection(ix, ELLIPSOID_INTERSECTION_INDEX_LATITUDE_MIN + 0, bottomConeIntersections[0]);\n\
setShapeIntersection(ix, ELLIPSOID_INTERSECTION_INDEX_LATITUDE_MIN + 1, bottomConeIntersections[1]);\n\
#endif\n\
#if defined(ELLIPSOID_HAS_RENDER_BOUNDS_LATITUDE_MAX_UNDER_HALF)\n\
RayShapeIntersection topConeIntersections[2];\n\
intersectFlippedCone(ray, u_ellipsoidRenderLatitudeSinMinMax.y, topConeIntersections);\n\
setShapeIntersection(ix, ELLIPSOID_INTERSECTION_INDEX_LATITUDE_MAX + 0, topConeIntersections[0]);\n\
setShapeIntersection(ix, ELLIPSOID_INTERSECTION_INDEX_LATITUDE_MAX + 1, topConeIntersections[1]);\n\
#elif defined(ELLIPSOID_HAS_RENDER_BOUNDS_LATITUDE_MAX_EQUAL_HALF)\n\
RayShapeIntersection topConeIntersection = intersectZPlane(ray, 1.0);\n\
setShapeIntersection(ix, ELLIPSOID_INTERSECTION_INDEX_LATITUDE_MAX, topConeIntersection);\n\
#elif defined(ELLIPSOID_HAS_RENDER_BOUNDS_LATITUDE_MAX_OVER_HALF)\n\
RayShapeIntersection topConeIntersection = intersectRegularCone(ray, u_ellipsoidRenderLatitudeSinMinMax.y, false);\n\
setShapeIntersection(ix, ELLIPSOID_INTERSECTION_INDEX_LATITUDE_MAX, topConeIntersection);\n\
#endif\n\
#if defined(ELLIPSOID_HAS_RENDER_BOUNDS_LONGITUDE_RANGE_EQUAL_ZERO)\n\
RayShapeIntersection wedgeIntersects[2];\n\
intersectHalfPlane(ray, u_ellipsoidRenderLongitudeMinMax.x, wedgeIntersects);\n\
setShapeIntersection(ix, ELLIPSOID_INTERSECTION_INDEX_LONGITUDE + 0, wedgeIntersects[0]);\n\
setShapeIntersection(ix, ELLIPSOID_INTERSECTION_INDEX_LONGITUDE + 1, wedgeIntersects[1]);\n\
#elif defined(ELLIPSOID_HAS_RENDER_BOUNDS_LONGITUDE_RANGE_UNDER_HALF)\n\
RayShapeIntersection wedgeIntersect = intersectRegularWedge(ray, u_ellipsoidRenderLongitudeMinMax);\n\
setShapeIntersection(ix, ELLIPSOID_INTERSECTION_INDEX_LONGITUDE, wedgeIntersect);\n\
#elif defined(ELLIPSOID_HAS_RENDER_BOUNDS_LONGITUDE_RANGE_OVER_HALF)\n\
RayShapeIntersection wedgeIntersects[2];\n\
intersectFlippedWedge(ray, u_ellipsoidRenderLongitudeMinMax, wedgeIntersects);\n\
setShapeIntersection(ix, ELLIPSOID_INTERSECTION_INDEX_LONGITUDE + 0, wedgeIntersects[0]);\n\
setShapeIntersection(ix, ELLIPSOID_INTERSECTION_INDEX_LONGITUDE + 1, wedgeIntersects[1]);\n\
#endif\n\
}\n\
";
