//This file is automatically rebuilt by the Cesium build process.
export default "#if !defined(LOG_DEPTH)\n\
in float v_WindowZ;\n\
#endif\n\
void czm_writeDepthClamp()\n\
{\n\
#if (!defined(LOG_DEPTH) && (__VERSION__ == 300 || defined(GL_EXT_frag_depth)))\n\
gl_FragDepth = clamp(v_WindowZ * gl_FragCoord.w, 0.0, 1.0);\n\
#endif\n\
}\n\
";
