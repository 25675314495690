import {
 Color,
 defined,
 DeveloperError,
} from "../../../Core/cesium/Source/Cesium.js";

/**
* A mixin which configures the default graphics settings.
* Rather than being called directly, this function is normally passed as
* a parameter to {@link Viewer#extend}, as shown in the example below.
* @function
*
* @param {Viewer} viewer The viewer instance.
* @param {Object} [options] An object with the following properties:
* @param {String} [options.locale] The {@link https://tools.ietf.org/html/rfc5646|BCP 47 language tag} string customizing language-sensitive number formatting. If <code>undefined</code>, the runtime's default locale is used. See the {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#Locale_identification_and_negotiation|Intl page on MDN}
*
* @exception {DeveloperError} viewer is required.
*
* @example
* var viewer = new Cesium.Viewer('cesiumContainer');
* viewer.extend(Cesium.viewerSkyboxDestroyMixin);
*/
function viewerGraphicsMixin(viewer, options) {

 if (!defined(viewer)) {
   throw new DeveloperError("viewer is required.");
 }

 if (viewer.scene.skyBox) viewer.scene.skyBox.destroy();
 viewer.scene.skyBox = undefined;
 if (viewer.scene.sun) viewer.scene.sun.destroy();
 viewer.scene.sun = undefined;
 if (viewer.scene.moon) viewer.scene.moon.destroy();
 viewer.scene.moon = undefined;
 if (viewer.scene.skyAtmosphere) viewer.scene.skyAtmosphere.destroy();
 viewer.scene.skyAtmosphere = undefined;
 viewer.scene.backgroundColor = Color.fromCssColorString('#20324A');
 viewer.scene.postProcessStages.fxaa.enabled = true;
}
export default viewerGraphicsMixin;
