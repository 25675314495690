import {
  BoundingSphere,
  Cartesian2,
  Cartesian3,
  Color,
  defined,
  Event,
  IntersectionTests,
  Matrix3,
  Matrix4,
  Plane,
  PrimitiveCollection,
  Ray,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  Transforms,
} from "../../../Core/cesium/Source/Cesium.js";
import AxisLinePrimitive from "../AxisLinePrimitive";
import getUnitZCirclePositions from "../getUnitZCirclePositions";
import getWidgetOrigin from "../getWidgetOrigin";

const scratchBoundingSphere = new BoundingSphere();
const noScale = new Cartesian3(1.0, 1.0, 1.0);
const polylineZId = "PolylineZ";
const scratchTransform = new Matrix4();

const offsetScratch = new Cartesian3();
const rotationWorldScratch = new Cartesian3();
const inverseTransformScratch = new Matrix4();
const localStartScratch = new Cartesian3();
const localEndScratch = new Cartesian3();
const vector1Scratch = new Cartesian3();
const vector2Scratch = new Cartesian3();
const rayScratch = new Ray();
const intersectionScratch = new Cartesian3();

function getRotationAngle(
  transform,
  originOffset,
  axis,
  start,
  end,
) {
  const inverseTransform = Matrix4.inverse(transform, inverseTransformScratch);
  let localStart = Matrix4.multiplyByPoint(inverseTransform, start, localStartScratch); // project points to local coordinates so we can project to 2D
  let localEnd = Matrix4.multiplyByPoint(inverseTransform, end, localEndScratch);

  localStart = Cartesian3.subtract(localStart, originOffset, localStart);
  localEnd = Cartesian3.subtract(localEnd, originOffset, localEnd);

  const v1 = vector1Scratch;
  const v2 = vector2Scratch;
  if (axis.x) {
    v1.x = localStart.y;
    v1.y = localStart.z;
    v2.x = localEnd.y;
    v2.y = localEnd.z;
  } else if (axis.y) {
    v1.x = -localStart.x;
    v1.y = localStart.z;
    v2.x = -localEnd.x;
    v2.y = localEnd.z;
  } else {
    v1.x = localStart.x;
    v1.y = localStart.y;
    v2.x = localEnd.x;
    v2.y = localEnd.y;
  }
  const ccw = v1.x * v2.y - v1.y * v2.x >= 0.0; // true when minimal angle between start and end is a counter clockwise rotation
  let angle = Cartesian2.angleBetween(v1, v2);
  if (!ccw) {
    angle = -angle;
  }
  return angle;
}

const rotationAxis = Cartesian3.UNIT_Z;

export default class RotationMarker {
  constructor(options) {
    const scene = options.scene;

    this._scene = scene;

    this._transform = scratchTransform;

    this._currentRotationAngleByUp = 0;
    this._accumulatedRotationAngle = 0.0;
    this._draggingCirclePolylinePrimitive = false;
    this._startTransform = new Matrix4();
    this._startRotation = new Matrix3();
    this.originOffset = Cartesian3.ZERO;
    this._widgetOrigin = new Cartesian3();
    this._modelOrigin = new Cartesian3();
    this._rotationPlane = new Plane(Cartesian3.UNIT_X, 0.0);
    this._rotationStartPoint = new Cartesian3();
    this._eventHandler = new ScreenSpaceEventHandler(scene.canvas);

    this._eventHandler.setInputAction(this._handleLeftDown.bind(this), ScreenSpaceEventType.LEFT_DOWN);
    this._eventHandler.setInputAction(this._handleMouseMove.bind(this), ScreenSpaceEventType.MOUSE_MOVE);
    this._eventHandler.setInputAction(this._handleLeftUp.bind(this), ScreenSpaceEventType.LEFT_UP);

    this._primitiveCollection = new PrimitiveCollection();

    // @ts-ignore
    this._primitiveCollection.id = "RotationMarker-PrimitiveCollection";

    scene.primitives.add(this._primitiveCollection);

    this._createPrimitives();

    this._rotated = new Event();
    this._rotationFinished = new Event();
  }

  clear() {
    this._primitiveCollection.removeAll();

    this._createPrimitives();
  }

  _createPrimitives() {
    const primitiveCollection = this._primitiveCollection;

    this._vectorLine1 = primitiveCollection.add(
      new AxisLinePrimitive({
        width: 5,
        positions: [new Cartesian3(), new Cartesian3()],
        color: Color.RED,
        show: false
      })
    );

    this._vectorLine2 = primitiveCollection.add(
      new AxisLinePrimitive({
        width: 5,
        positions: [new Cartesian3(), new Cartesian3()],
        color: Color.YELLOW,
        show: false
      })
    );

    this._polylineZ = primitiveCollection.add(
      new AxisLinePrimitive({
        positions: getUnitZCirclePositions(),
        color: Color.BLUE,
        loop: true,
        show: false,
        id: polylineZId
      })
    );
  }

  get rotated() {
    return this._rotated;
  }

  get rotationFinished() {
    return this._rotationFinished;
  }

  _handleLeftDown(event) {
    const position = event.position;

    const scene = this._scene;
    const pickedObjects = scene.drillPick(position);
    let pickedAxis;

    for (let i = 0; i < pickedObjects.length; i++) {
      const object = pickedObjects[i];
      if (defined(object.id) && object.id === polylineZId) {
        pickedAxis = object.id;
        break;
      }
    }

    if (!defined(pickedAxis)) {
      return;
    }

    const startTransform = Matrix4.setScale(this._transform, noScale, this._startTransform);

    this._startRotation = Matrix4.getMatrix3(startTransform, this._startRotation);

    const modelOrigin = Matrix4.getTranslation(startTransform, this._modelOrigin);

    const widgetOrigin = getWidgetOrigin(this._transform, this.originOffset, this._widgetOrigin);

    const rotationAxisEndWorld = Matrix4.multiplyByPoint(startTransform, rotationAxis, rotationWorldScratch);
    let rotationAxisVectorWorld = Cartesian3.subtract(rotationAxisEndWorld, modelOrigin, rotationAxisEndWorld);

    rotationAxisVectorWorld = Cartesian3.normalize(rotationAxisVectorWorld, rotationAxisVectorWorld);

    const rotationPlane = Plane.fromPointNormal(widgetOrigin, rotationAxisVectorWorld, this._rotationPlane);
    const ray = scene.camera.getPickRay(position, rayScratch);
    const rotationStartPoint = IntersectionTests.rayPlane(ray, rotationPlane, this._rotationStartPoint);

    this._draggingCirclePolylinePrimitive = defined(rotationStartPoint);
    scene.screenSpaceCameraController.enableInputs = false;
    this._currentRotationAngleByUp = 0;
  }

  _doHighlightOrUnHighlightLogicPolylineZ(movement) {
    const position = movement.endPosition;

    const scene = this._scene;
    const pickedObject = scene.pick(position);

    if (pickedObject && pickedObject.id && pickedObject.id === polylineZId) {
      if (!Color.equals(this._polylineZ.color, Color.YELLOW)) {
        this._polylineZ.color = Color.YELLOW;
      }

      return;
    }

    if (!Color.equals(this._polylineZ.color, Color.BLUE)) {
      this._polylineZ.color = Color.BLUE;
    }
  }

  _handleMouseMove(movement) {
    this._doHighlightOrUnHighlightLogicPolylineZ(movement);

    if (!this._draggingCirclePolylinePrimitive) {
      return;
    }

    const scene = this._scene;
    const position = movement.endPosition;
    const ray = scene.camera.getPickRay(position, rayScratch);
    let intersection = IntersectionTests.rayPlane(ray, this._rotationPlane, intersectionScratch);

    if (!defined(intersection)) {
      return;
    }

    const widgetOrigin = this._widgetOrigin;
    const rotationStartPoint = this._rotationStartPoint;
    const vector1 = this._vectorLine1;
    const v1Pos = vector1.positions;
    const vector2 = this._vectorLine2;
    const v2Pos = vector2.positions;

    const v1 = Cartesian3.subtract(rotationStartPoint, widgetOrigin, vector1Scratch);
    let v2 = Cartesian3.subtract(intersection, widgetOrigin, vector2Scratch);

    v2 = Cartesian3.normalize(v2, v2);
    v2 = Cartesian3.multiplyByScalar(v2, Cartesian3.magnitude(v1), v2);

    intersection = Cartesian3.add(widgetOrigin, v2, intersection);

    v1Pos[0] = widgetOrigin;
    v1Pos[1] = rotationStartPoint;
    v2Pos[0] = widgetOrigin;
    v2Pos[1] = intersection;

    vector1.positions = v1Pos;
    vector2.positions = v2Pos;
    vector1.show = true;
    vector2.show = true;

    const offset = Cartesian3.multiplyComponents(
      this.originOffset,
      Matrix4.getScale(this._transform, offsetScratch),
      offsetScratch
    );

    const angle = getRotationAngle(this._startTransform, offset, rotationAxis, rotationStartPoint, intersection);

    this._currentRotationAngleByUp = angle;

    // @ts-ignore
    this._rotated.raiseEvent(angle);
  }

  _handleLeftUp() {
    if (!this._draggingCirclePolylinePrimitive) {
      return;
    }

    this._draggingCirclePolylinePrimitive = false;
    this._vectorLine1.show = false;
    this._vectorLine2.show = false;
    this._scene.screenSpaceCameraController.enableInputs = true;

    this._accumulatedRotationAngle += this._currentRotationAngleByUp;

    this._rotationFinished.raiseEvent();
  }

  update(positions) {
    const boundingSphere = BoundingSphere.fromPoints(positions, scratchBoundingSphere);

    this._transform = Transforms.eastNorthUpToFixedFrame(boundingSphere.center, undefined, scratchTransform);

    const radius = boundingSphere.radius * Matrix4.getMaximumScale(this._transform) * 1.25;

    this._polylineZ.modelMatrix = Matrix4.multiplyByUniformScale(this._transform, radius, new Matrix4());
    this._polylineZ.show = true;
  }

  get accumulatedRotationAngle() {
    return this._accumulatedRotationAngle;
  }

  reset() {
    this._accumulatedRotationAngle = 0;
  }

  get show() {
    return this._polylineZ.show;
  }

  set show(b) {
    this._polylineZ.show = b;
  }
}