/**
 * Distance units used for the measure widget.
 *
 * @enum {String}
 */
const DistanceUnits = {
  /**
   * @type {String}
   * @constant
   */
  INCHES: "INCHES",

  /**
   * @type {String}
   * @constant
   */
  FEET: "FEET",

  /**
   * @type {String}
   * @constant
   */
  US_SURVEY_FEET: "US_SURVEY_FEET",

  /**
   * @type {String}
   * @constant
   */
  YARDS: "YARDS",

  /**
   * @type {String}
   * @constant
   */
  MILES: "MILES",

  /**
   * @type {String}
   * @constant
   */
  CENTIMETERS: "CENTIMETERS",

  /**
   * @type {String}
   * @constant
   */
  METERS: "METERS",


  /**
   * @type {String}
   * @constant
   */
  KILOMETERS: "KILOMETERS",

};

export default Object.freeze(DistanceUnits);
