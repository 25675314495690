/* eslint-disable */
// @ts-nocheck

export default class DXFLayer {
    constructor(name, colorNumber, lineTypeName) {
        this.name = name;
        this.colorNumber = colorNumber;
        this.lineTypeName = lineTypeName;
        this.shapes = [];
        this.trueColor = -1;
    }

    toDxfString() {
        let s = "0\nLAYER\n";
        s += "70\n64\n";
        s += `2\n${this.name}\n`;
        if (this.trueColor !== -1) {
            s += `420\n${this.trueColor}\n`;
        } else {
            s += `62\n${this.colorNumber}\n`;
        }
        s += `6\n${this.lineTypeName}\n`;
        return s;
    }

    setTrueColor(color) {
        this.trueColor = color;
    }

    addShape(shape) {
        this.shapes.push(shape);
        shape.layer = this;
    }

    getShapes() {
        return this.shapes;
    }

    shapesToDxf() {
        let s = "";
        for (let i = 0; i < this.shapes.length; ++i) {
            s += this.shapes[i].toDxfString();
        }

        return s;
    }
}
