import {
  clone,
  defaultValue,
  defined,
  destroyObject,
  Check,
  LabelCollection,
  PointPrimitiveCollection,
  PrimitiveCollection,
  knockout,
} from "../../../Core/cesium/Source/Cesium.js";

import AreaMeasurement from "./AreaMeasurement.js";
import DataManagement from "./DataManagement.js";
import DistanceMeasurement from "./DistanceMeasurement.js";
import HeightMeasurement from "./HeightMeasurement.js";
import TerrainMeasurement from "./TerrainMeasurement.js";
import HorizontalMeasurement from "./HorizontalMeasurement.js";
import MeasurementMouseHandler from "./MeasurementMouseHandler.js";
import MeasureUnits from "./MeasureUnits.js";
import PointMeasurement from "./PointMeasurement.js";
import PolylineMeasurement from "./PolylineMeasurement.js";
import VerticalMeasurement from "./VerticalMeasurement.js";

/**
 * A widget for making ephemeral measurements.
 * @alias MeasureViewModel
 *
 * @param {Object} options An object with the following properties:
 * @param {Scene} options.scene The scene
 * @param {String|Element} options.container The container for the widget
 * @param {MeasureUnits} [options.units] The units of measurement
 * @param {String} [options.locale] The {@link https://tools.ietf.org/html/rfc5646|BCP 47 language tag} string customizing language-sensitive number formatting. If <code>undefined</code>, the runtime's default locale is used. See the {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl#Locale_identification_and_negotiation|Intl page on MDN}
 * @param {PrimitiveCollection} [options.primitives] A collection in which to store the measurement primitives
 *
 * @constructor
 */
function MeasureViewModel(options) {
  options = defaultValue(options, defaultValue.EMPTY_OBJECT);

  const scene = options.scene;
  //>>includeStart('debug', pragmas.debug);
  Check.defined("options.scene", scene);
  //>>includeEnd('debug');

  const units = defined(options.units) ? options.units : new MeasureUnits();
  const primitives = defined(options.primitives)
    ? options.primitives
    : scene.primitives.add(new PrimitiveCollection());
  const points = primitives.add(new PointPrimitiveCollection());
  const labels = primitives.add(new LabelCollection());

  const mouseHandler = new MeasurementMouseHandler(scene);
  const measurementOptions = {
    scene: scene,
    container: options.container,
    units: units,
    locale: options.locale,
    points: points,
    labels: labels,
    primitives: primitives,
  };
  const componentOptions = clone(measurementOptions);
  componentOptions.showComponentLines = true;

  this.dataManagement = new DataManagement(measurementOptions);

  const measurements = [
    new DistanceMeasurement(measurementOptions),
    new DistanceMeasurement(componentOptions),
    new PolylineMeasurement(measurementOptions),
    new HorizontalMeasurement(measurementOptions),
    new VerticalMeasurement(measurementOptions),
    new HeightMeasurement(measurementOptions),
    new TerrainMeasurement(measurementOptions),
    new AreaMeasurement(measurementOptions),
    new PointMeasurement(measurementOptions),
  ];

  /**
   * Gets and sets whether the data managment widget is displayed.
   * @type {Boolean}
   * @default false
   */
  this.showDataManagement = false;

  /**
   * Gets and sets whether the instructions are visible.
   * @type {Boolean}
   * @default false
   */
  this.instructionsVisible = false;

  /**
   * Gets or sets the currently selected measurement.  This property is observable.
   * @type {Measurement}
   * @default undefined
   */
  this.selectedMeasurement = undefined;
  const selectedMeasurement = knockout.observable();
  knockout.defineProperty(this, "selectedMeasurement", {
    get: function () {
      return selectedMeasurement();
    },
    set: function (value) {
      const old = selectedMeasurement();
      if (defined(old)) {
        old.reset();
      }
      if (value) {
        if (value.isDrawingTool()) {
          selectedMeasurement(value);
          mouseHandler.selectedMeasurement = value;
        }
        this._toggleActiveTool(value);
      } else if (!value || !value.isDrawingTool()) {
        selectedMeasurement(this.selectedMeasurement);
        mouseHandler.selectedMeasurement = this.selectedMeasurement;
      }

      if (scene.requestRenderMode) {
        scene.requestRender();
      }
    },
  });

  knockout.track(this, ["instructionsVisible"]);

  this._measurements = measurements;
  this._units = units;
  this._mouseHandler = mouseHandler;
  this._primitives = primitives;

  this._scene = scene;

  this._removeListener = scene.morphStart.addEventListener(
    MeasureViewModel.prototype.onMorph,
    this
  );

  //listen for measurement remove and reset current measurement
  document.addEventListener(
    "measurementRemoved",
    function (e) {
      this.selectedMeasurement.reset();
    }.bind(this)
  );

  //listen for redraw measurement event and call method within selected measurement, switch to right measurement type
  document.addEventListener(
    "redrawDistance",
    function (e) {
      //regular distance measurement
      if (
        e.detail.measurementType == "DistanceMeasurement" &&
        !e.detail.componentLines
      )
        this.selectedMeasurement = this._measurements[0];

      //distance measurement with component lines
      if (
        e.detail.measurementType == "DistanceMeasurement" &&
        e.detail.componentLines
      )
        this.selectedMeasurement = this._measurements[1];

      //polyline measurement
      if (e.detail.measurementType == "PolylineDrawing")
        this.selectedMeasurement = this._measurements[2];

      //horizontal polyline measurement
      if (e.detail.measurementType == "HorizontalPolylineDrawing")
        this.selectedMeasurement = this._measurements[3];

      //vertical measurement
      if (e.detail.measurementType == "VerticalMeasurement")
        this.selectedMeasurement = this._measurements[4];

      //height measurement
      if (e.detail.measurementType == "HeightMeasurement")
        this.selectedMeasurement = this._measurements[5];

      //terrain measurement
      if (e.detail.measurementType == "TerrainMeasurement")
        this.selectedMeasurement = this._measurements[6];

      //area measurement
      if (e.detail.measurementType == "PolygonDrawing")
        this.selectedMeasurement = this._measurements[7];

      this.selectedMeasurement._redrawMeasurements(e);
    }.bind(this)
  );
}

Object.defineProperties(MeasureViewModel.prototype, {
  /**
   * Gets the scene.
   * @type {Scene}
   * @memberof MeasureViewModel.prototype
   * @readonly
   */
  scene: {
    get: function () {
      return this._scene;
    },
  },
  /**
   * Gets the array of available measurement types.
   * @type {Measurement[]}
   * @memberof MeasureViewModel.prototype
   * @readonly
   */
  measurements: {
    get: function () {
      return this._measurements;
    },
  },
  /**
   * Gets the selected unit of measurement.
   * @type {MeasureUnits}
   * @memberof MeasureViewModel.prototype
   */
  units: {
    get: function () {
      return this._units;
    },
    set: function (value) {
      this._units = value;
      this.selectedMeasurement.selectedUnits = value;
    },
  },
});

/**
 * Toggles the state of data management widget
 */
MeasureViewModel.prototype.toggleDataManagementWidget = function () {
  this.showDataManagement = !this.showDataManagement;
  this.dataManagement.toggleActive(this.showDataManagement);
};

/**
 * Toggles the state of active tools
 */

MeasureViewModel.prototype._toggleActiveTool = function (measureTool) {
  this._measurements.forEach(function (measure) {
    if (measure.isDrawingTool() && measureTool.isDrawingTool()) {
      measure.toggleActive(measure == measureTool);
    } else if (measure == measureTool) {
      measure.toggleActive();
    }
  });
};

/**
 * Toggles the visibility of the instructions panel.
 */
MeasureViewModel.prototype.toggleInstructions = function () {
  this.instructionsVisible = !this.instructionsVisible;
};

/**
 * @private
 */
MeasureViewModel.prototype._activate = function () {
  this._mouseHandler.activate();
};

/**
 * @private
 */
MeasureViewModel.prototype._deactivate = function () {
  this._mouseHandler.deactivate();
  this.selectedMeasurement = undefined;
  this.reset();
};

/**
 * @private
 */
MeasureViewModel.prototype._deactivateMouseHandler = function () {
  this._mouseHandler.deactivate();
};

MeasureViewModel.prototype.onMorph = function (
  transitioner,
  oldMode,
  newMode,
  isMorphing
) {
  this.reset();
};

/**
 * Resets the widget.
 */
MeasureViewModel.prototype.reset = function () {
  this.instructionsVisible = false;
  this._measurements.forEach(function (measurement) {
    measurement.reset();
  });
};

/**
 * @returns {Boolean} true if the object has been destroyed, false otherwise.
 */
MeasureViewModel.prototype.isDestroyed = function () {
  return false;
};

/**
 * Destroys the widget view model.
 */
MeasureViewModel.prototype.destroy = function () {
  this._deactivate();
  this._mouseHandler.destroy();
  this._measurements.forEach(function (measurement) {
    measurement.destroy();
  });
  this._scene.primitives.remove(this._primitives);
  return destroyObject(this);
};

export default MeasureViewModel;
