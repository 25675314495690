//This file is automatically rebuilt by the Cesium build process.
export default "#if defined(CYLINDER_HAS_SHAPE_BOUNDS_RADIUS)\n\
uniform vec2 u_cylinderUvToShapeUvRadius;\n\
#endif\n\
#if defined(CYLINDER_HAS_SHAPE_BOUNDS_HEIGHT)\n\
uniform vec2 u_cylinderUvToShapeUvHeight;\n\
#endif\n\
#if defined(CYLINDER_HAS_SHAPE_BOUNDS_ANGLE)\n\
uniform vec2 u_cylinderUvToShapeUvAngle;\n\
#endif\n\
#if defined(CYLINDER_HAS_SHAPE_BOUNDS_ANGLE_MIN_DISCONTINUITY) || defined(CYLINDER_HAS_SHAPE_BOUNDS_ANGLE_MAX_DISCONTINUITY)\n\
uniform vec2 u_cylinderShapeUvAngleMinMax;\n\
#endif\n\
#if defined(CYLINDER_HAS_SHAPE_BOUNDS_ANGLE_MIN_DISCONTINUITY) || defined(CYLINDER_HAS_SHAPE_BOUNDS_ANGLE_MAX_DISCONTINUITY) || defined(CYLINDER_HAS_SHAPE_BOUNDS_ANGLE_MIN_MAX_REVERSED)\n\
uniform float u_cylinderShapeUvAngleRangeZeroMid;\n\
#endif\n\
PointJacobianT convertUvToShapeSpaceDerivative(in vec3 positionUv) {\n\
vec3 position = positionUv * 2.0 - 1.0;\n\
float radius = length(position.xy);\n\
vec3 radial = normalize(vec3(position.xy, 0.0));\n\
float height = positionUv.z;\n\
vec3 z = vec3(0.0, 0.0, 1.0);\n\
float angle = atan(position.y, position.x);\n\
vec3 east = normalize(vec3(-position.y, position.x, 0.0));\n\
vec3 point = vec3(radius, height, angle);\n\
mat3 jacobianT = mat3(radial, z, east / length(position.xy));\n\
return PointJacobianT(point, jacobianT);\n\
}\n\
vec3 convertShapeToShapeUvSpace(in vec3 positionShape) {\n\
float radius = positionShape.x;\n\
#if defined(CYLINDER_HAS_SHAPE_BOUNDS_RADIUS)\n\
radius = radius * u_cylinderUvToShapeUvRadius.x + u_cylinderUvToShapeUvRadius.y;\n\
#endif\n\
float height = positionShape.y;\n\
#if defined(CYLINDER_HAS_SHAPE_BOUNDS_HEIGHT)\n\
height = height * u_cylinderUvToShapeUvHeight.x + u_cylinderUvToShapeUvHeight.y;\n\
#endif\n\
float angle = (positionShape.z + czm_pi) / czm_twoPi;\n\
#if defined(CYLINDER_HAS_SHAPE_BOUNDS_ANGLE)\n\
#if defined(CYLINDER_HAS_SHAPE_BOUNDS_ANGLE_MIN_MAX_REVERSED)\n\
angle += float(angle < u_cylinderShapeUvAngleRangeZeroMid);\n\
#endif\n\
#if defined(CYLINDER_HAS_SHAPE_BOUNDS_ANGLE_MIN_DISCONTINUITY)\n\
angle = angle > u_cylinderShapeUvAngleRangeZeroMid ? u_cylinderShapeUvAngleMinMax.x : angle;\n\
#elif defined(CYLINDER_HAS_SHAPE_BOUNDS_ANGLE_MAX_DISCONTINUITY)\n\
angle = angle < u_cylinderShapeUvAngleRangeZeroMid ? u_cylinderShapeUvAngleMinMax.y : angle;\n\
#endif\n\
angle = angle * u_cylinderUvToShapeUvAngle.x + u_cylinderUvToShapeUvAngle.y;\n\
#endif\n\
return vec3(radius, height, angle);\n\
}\n\
PointJacobianT convertUvToShapeUvSpaceDerivative(in vec3 positionUv) {\n\
PointJacobianT pointJacobian = convertUvToShapeSpaceDerivative(positionUv);\n\
pointJacobian.point = convertShapeToShapeUvSpace(pointJacobian.point);\n\
return pointJacobian;\n\
}\n\
vec3 scaleShapeUvToShapeSpace(in vec3 shapeUv) {\n\
float radius = shapeUv.x;\n\
#if defined(CYLINDER_HAS_SHAPE_BOUNDS_RADIUS)\n\
radius /= u_cylinderUvToShapeUvRadius.x;\n\
#endif\n\
float height = shapeUv.y;\n\
#if defined(CYLINDER_HAS_SHAPE_BOUNDS_HEIGHT)\n\
height /= u_cylinderUvToShapeUvHeight.x;\n\
#endif\n\
float angle = shapeUv.z * czm_twoPi;\n\
#if defined(CYLINDER_HAS_SHAPE_BOUNDS_ANGLE)\n\
angle /= u_cylinderUvToShapeUvAngle.x;\n\
#endif\n\
return vec3(radius, height, angle);\n\
}\n\
";
