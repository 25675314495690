//This file is automatically rebuilt by the Cesium build process.
export default "void czm_computeScattering(\n\
czm_ray primaryRay,\n\
float primaryRayLength,\n\
vec3 lightDirection,\n\
float atmosphereInnerRadius,\n\
out vec3 rayleighColor,\n\
out vec3 mieColor,\n\
out float opacity\n\
) {\n\
const float ATMOSPHERE_THICKNESS = 111e3;\n\
const int PRIMARY_STEPS_MAX = 16;\n\
const int LIGHT_STEPS_MAX = 4;\n\
rayleighColor = vec3(0.0);\n\
mieColor = vec3(0.0);\n\
opacity = 0.0;\n\
float atmosphereOuterRadius = atmosphereInnerRadius + ATMOSPHERE_THICKNESS;\n\
vec3 origin = vec3(0.0);\n\
czm_raySegment primaryRayAtmosphereIntersect = czm_raySphereIntersectionInterval(primaryRay, origin, atmosphereOuterRadius);\n\
if (primaryRayAtmosphereIntersect == czm_emptyRaySegment) {\n\
rayleighColor = vec3(1.0, 0.0, 1.0);\n\
return;\n\
}\n\
float x = 1e-7 * primaryRayAtmosphereIntersect.stop / length(primaryRayLength);\n\
float w_stop_gt_lprl = 0.5 * (1.0 + czm_approximateTanh(x));\n\
float start_0 = primaryRayAtmosphereIntersect.start;\n\
primaryRayAtmosphereIntersect.start = max(primaryRayAtmosphereIntersect.start, 0.0);\n\
primaryRayAtmosphereIntersect.stop = min(primaryRayAtmosphereIntersect.stop, length(primaryRayLength));\n\
float x_o_a = start_0 - ATMOSPHERE_THICKNESS;\n\
float w_inside_atmosphere = 1.0 - 0.5 * (1.0 + czm_approximateTanh(x_o_a));\n\
int PRIMARY_STEPS = PRIMARY_STEPS_MAX - int(w_inside_atmosphere * 12.0);\n\
int LIGHT_STEPS = LIGHT_STEPS_MAX - int(w_inside_atmosphere * 2.0);\n\
float rayPositionLength = primaryRayAtmosphereIntersect.start;\n\
float totalRayLength = primaryRayAtmosphereIntersect.stop - rayPositionLength;\n\
float rayStepLengthIncrease = w_inside_atmosphere * ((1.0 - w_stop_gt_lprl) * totalRayLength / (float(PRIMARY_STEPS * (PRIMARY_STEPS + 1)) / 2.0));\n\
float rayStepLength = max(1.0 - w_inside_atmosphere, w_stop_gt_lprl) * totalRayLength / max(7.0 * w_inside_atmosphere, float(PRIMARY_STEPS));\n\
vec3 rayleighAccumulation = vec3(0.0);\n\
vec3 mieAccumulation = vec3(0.0);\n\
vec2 opticalDepth = vec2(0.0);\n\
vec2 heightScale = vec2(czm_atmosphereRayleighScaleHeight, czm_atmosphereMieScaleHeight);\n\
for (int i = 0; i < PRIMARY_STEPS_MAX; ++i) {\n\
if (i >= PRIMARY_STEPS) {\n\
break;\n\
}\n\
vec3 samplePosition = primaryRay.origin + primaryRay.direction * (rayPositionLength + rayStepLength);\n\
float sampleHeight = length(samplePosition) - atmosphereInnerRadius;\n\
vec2 sampleDensity = exp(-sampleHeight / heightScale) * rayStepLength;\n\
opticalDepth += sampleDensity;\n\
czm_ray lightRay = czm_ray(samplePosition, lightDirection);\n\
czm_raySegment lightRayAtmosphereIntersect = czm_raySphereIntersectionInterval(lightRay, origin, atmosphereOuterRadius);\n\
float lightStepLength = lightRayAtmosphereIntersect.stop / float(LIGHT_STEPS);\n\
float lightPositionLength = 0.0;\n\
vec2 lightOpticalDepth = vec2(0.0);\n\
for (int j = 0; j < LIGHT_STEPS_MAX; ++j) {\n\
if (j >= LIGHT_STEPS) {\n\
break;\n\
}\n\
vec3 lightPosition = samplePosition + lightDirection * (lightPositionLength + lightStepLength * 0.5);\n\
float lightHeight = length(lightPosition) - atmosphereInnerRadius;\n\
lightOpticalDepth += exp(-lightHeight / heightScale) * lightStepLength;\n\
lightPositionLength += lightStepLength;\n\
}\n\
vec3 attenuation = exp(-((czm_atmosphereMieCoefficient * (opticalDepth.y + lightOpticalDepth.y)) + (czm_atmosphereRayleighCoefficient * (opticalDepth.x + lightOpticalDepth.x))));\n\
rayleighAccumulation += sampleDensity.x * attenuation;\n\
mieAccumulation += sampleDensity.y * attenuation;\n\
rayPositionLength += (rayStepLength += rayStepLengthIncrease);\n\
}\n\
rayleighColor = czm_atmosphereRayleighCoefficient * rayleighAccumulation;\n\
mieColor = czm_atmosphereMieCoefficient * mieAccumulation;\n\
opacity = length(exp(-((czm_atmosphereMieCoefficient * opticalDepth.y) + (czm_atmosphereRayleighCoefficient * opticalDepth.x))));\n\
}\n\
";
