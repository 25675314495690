//This file is automatically rebuilt by the Cesium build process.
export default "void metadataStage(\n\
out Metadata metadata,\n\
out MetadataClass metadataClass,\n\
out MetadataStatistics metadataStatistics,\n\
ProcessedAttributes attributes\n\
)\n\
{\n\
initializeMetadata(metadata, metadataClass, metadataStatistics, attributes);\n\
}\n\
";
