import { defined, DeveloperError } from "@cesium/engine";
import { createCommand } from "@cesium/widgets";
import knockout from "@cesium/widgets/Source/ThirdParty/knockout.js";

/**
 * The view model for {@link SettingsButton}.
 * @alias SettingsButtonViewModel
 * @constructor
 *
 * @param {Element|String} container The DOM element or ID that will contain the widget.
 * @param {Scene} scene The viewer instance to use.
 * @param {Cesium3DTileset} tileset The current tileset
 * @param {Boolean} georef Whether the model is georeferenced or not
 */
function SettingsButtonViewModel(container, scene, tileset, georef) {
  if (!defined(scene)) {
    throw new DeveloperError("scene is required.");
  }

  const that = this;
  this.showSettingsWindow = false;

  this._scene = scene;
  this._command = createCommand( () => toggleSettings(!that.showSettingsWindow));

  const showCompass = (show) => {
    const compassContainer = document.getElementById('cesium-viewer-compassContainer');
    compassContainer.style.display = show ? 'block' : 'none';
  };
  const disableAA = (disable) => {
    scene.postProcessStages.fxaa.enabled = !disable;
  };
  const disableCollisionDetection = (disable) => {
    scene.screenSpaceCameraController.enableCollisionDetection = !disable;
  };

  const initialSettings = {
    disableCollisionDetection: localStorage.getItem('disableCollisionDetection') === "true",
    showCompass: localStorage.getItem('showCompass') !== "false",
    disableAA: localStorage.getItem('disableAA') === "true"
  };

  if (initialSettings.showCompass) {
    showCompass(true);
  }
  if (initialSettings.disableCollisionDetection) {
    disableCollisionDetection(true);
  }
  if (initialSettings.disableAA) {
    disableAA(true);
  }

  this._settingsCollisionDetection = knockout.observable(initialSettings.disableCollisionDetection);
  this._settingsEnableCompass = knockout.observable(initialSettings.showCompass);
  this._settingsAA = knockout.observable(initialSettings.disableAA);

  this._settingsCollisionDetection.subscribe((value) => {
    disableCollisionDetection(!!value);
    localStorage.setItem('disableCollisionDetection', ""+value);
  }, this);

  this._settingsEnableCompass.subscribe((value) => {
    showCompass(!!value);
    localStorage.setItem('showCompass', ""+value);
  }, this);

  this._settingsAA.subscribe((value) => {
    disableAA(!!value);
    localStorage.setItem('disableAA', ""+value);
  }, this);

  //Hides the settings window if clicked outside of it
  const onClickAnywhere = (e) => {
    const settingsButton = document.querySelector('.cesium-settings-button');
    const settingsWindow = document.getElementById('cesium-settings-widget');
    let targetEl = e.target;
    do {
      if (targetEl === settingsButton || targetEl === settingsWindow) return;
      targetEl = targetEl.parentNode;
    } while (targetEl);
    toggleSettings(false);
  };

  const toggleSettings = (showSettings) => {
    this.showSettingsWindow = showSettings;
    const settingsButton = document.querySelector('.cesium-settings-button');
    if (this.showSettingsWindow) {
      settingsButton.classList.add('active');
      document.addEventListener('click', onClickAnywhere);
    } else {
      settingsButton.classList.remove('active');
      document.removeEventListener('click', onClickAnywhere);
    }
    const settingsWindow = document.getElementById('cesium-settings-widget');
    settingsWindow.style.maxHeight = this.showSettingsWindow ? `300px` : 0;
    settingsWindow.style.padding =  this.showSettingsWindow ? `16px` : 0;
    const children = settingsWindow.childNodes;
    for (const node of children) {
      node.style.padding = 0;
    }
  };
  /**
   * Gets or sets the tooltip.  This property is observable.
   *
   * @type {String}
   */
  this.tooltip = "Settings";

  knockout.track(this, [
    "tooltip",
    "showSettingsWindow",
    "settingsCollisionDetection",
    "settingsEnableCompass",
    "settingsAA"
  ]);
}

Object.defineProperties(SettingsButtonViewModel.prototype, {
  /**
   * Gets the scene to control.
   * @memberof SettingsButtonViewModel.prototype
   *
   * @type {Scene}
   */
  scene: {
    get: function () {
      return this._scene;
    },
  },

  /**
   * Gets the Command that is executed when the button is clicked.
   * @memberof SettingsButtonViewModel.prototype
   *
   * @type {Command}
   */
  command: {
    get: function () {
      return this._command;
    },
  },
  /**
   * Gets or sets the collision detection setting
   *
   * @type {Boolean}
   * @default false
   */
  settingsCollisionDetection: {
    get: function () {
      return this._settingsCollisionDetection;
    },
    set: function () {
      this._settingsCollisionDetection = !this._settingsCollisionDetection;
    }
  },

  settingsEnableCompass: {
    get: function () {
      return this._settingsEnableCompass;
    },
    set: function () {
      this._settingsEnableCompass = !this._settingsEnableCompass;
    }
  },

  settingsAA: {
    get: function () {
      return this._settingsAA;
    },
    set: function () {
      this._settingsAA = !this._settingsAA;
    }
  }
});

export default SettingsButtonViewModel;
