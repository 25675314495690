//This file is automatically rebuilt by the Cesium build process.
export default "RayShapeIntersection intersectScene(in vec2 screenCoord, in Ray ray, out Intersections ix) {\n\
intersectShape(ray, ix);\n\
RayShapeIntersection intersection = getFirstIntersection(ix);\n\
if (intersection.entry.w == NO_HIT) {\n\
return intersection;\n\
}\n\
#if defined(CLIPPING_PLANES)\n\
intersectClippingPlanes(ray, ix);\n\
#endif\n\
#if defined(DEPTH_TEST)\n\
intersectDepth(screenCoord, ray, ix);\n\
#endif\n\
#if (INTERSECTION_COUNT > 1)\n\
initializeIntersections(ix);\n\
for (int i = 0; i < INTERSECTION_COUNT; ++i) {\n\
intersection = nextIntersection(ix);\n\
if (intersection.exit.w > 0.0) {\n\
intersection.entry.w = max(intersection.entry.w, 0.0);\n\
break;\n\
}\n\
}\n\
#else\n\
intersection.entry.w = max(intersection.entry.w, 0.0);\n\
#endif\n\
return intersection;\n\
}\n\
";
