/* eslint-disable */
// @ts-nocheck

export default class DXFPolyline {
    /**
     * @param {array} points - Array of points like [ [x1, y1], [x2, y2, bulge]... ]
     * @param {boolean} closed
     * @param {number} startWidth
     * @param {number} endWidth
     */
    constructor(points, closed = false, startWidth = 0, endWidth = 0) {
        this.points = points;
        this.closed = closed;
        this.startWidth = startWidth;
        this.endWidth = endWidth;
    }

    toDxfString() {
        //https://www.autodesk.com/techpubs/autocad/acad2000/dxf/polyline_dxf_06.htm
        //https://www.autodesk.com/techpubs/autocad/acad2000/dxf/vertex_dxf_06.htm
        let s = `0\nPOLYLINE\n`;
        s += `8\n${this.layer.name}\n`;
        s += `66\n1\n70\n${this.closed ? 1 : 0}\n`;
        if (this.startWidth !== 0 || this.endWidth !== 0) {
            s += `40\n${this.startWidth}\n41\n${this.endWidth}\n`;
        }

        for (let i = 0; i < this.points.length; ++i) {
            s += `0\nVERTEX\n`;
            s += `8\n${this.layer.name}\n`;
            s += `70\n0\n`;
            s += `10\n${this.points[i][0]}\n20\n${this.points[i][1]}\n`;
            if (this.points[i][2] !== undefined) {
                s += `42\n${this.points[i][2]}\n`;
            }
        }

        s += `0\nSEQEND\n`;
        return s;
    }
}
