import {
  Cartographic,
  Cartesian3,
  Ellipsoid,
  HeadingPitchRoll,
  Matrix3,
  Matrix4,
  Math as CesiumMath,
  Quaternion,
  Transforms
} from "../../Core/cesium/Source/Cesium.js";

export function georeferencedAtECEF(tileset) {
  if (tileset.asset.extras && tileset.asset.extras.ion && tileset.asset.extras.ion.georeferenced === true) {
    return true;
  }

  if (tileset.boundingSphere.center.equals(Cartesian3.ZERO)) {
    return false;
  }

  // asset.extras.ion property is not available for some tileset generated by RealityCapture.
  const cartographic = Cartographic.fromCartesian(tileset.boundingSphere.center);

  //  Mariana Trench
  if (cartographic.height < -10994) return false;

  if (cartographic.height > 8848) return false;

  if (cartographic.longitude > CesiumMath.PI || cartographic.longitude < -CesiumMath.PI) return false;

  if (cartographic.latitude > CesiumMath.PI_OVER_TWO || cartographic.latitude < -CesiumMath.PI_OVER_TWO) return false;

  return true;
}

export function updateModelMatrixOfTilesetDefinedAtLocal(
  tileset,
  position,
  refInLocal,
  hpr,
  scale,
) {
  const toWorld = Transforms.eastNorthUpToFixedFrame(position);

  const T = Matrix4.fromTranslation(Cartesian3.negate(refInLocal, new Cartesian3()));
  // @ts-ignore
  const R = Matrix4.fromRotation(Matrix3.fromQuaternion(Quaternion.fromHeadingPitchRoll(hpr)));
  const S = Matrix4.fromScale(scale, new Matrix4());

  const modelMatrix = toWorld;

  Matrix4.multiply(modelMatrix, R, modelMatrix);
  Matrix4.multiply(modelMatrix, S, modelMatrix);
  Matrix4.multiply(toWorld, T, modelMatrix);

  tileset.modelMatrix = modelMatrix;
}

export function updateModelMatrixOfTilesetDefinedAtECEF(
  tileset,
  position,
  geoRefAtLocal,
  hpr,
  scale,
) {
  tileset.modelMatrix = Matrix4.IDENTITY;

  const origin = Cartesian3.clone(tileset.boundingSphere.center);
  const referenceFrame = Transforms.eastNorthUpToFixedFrame(origin);
  const toLocal = Matrix4.inverseTransformation(referenceFrame, new Matrix4());
  const T = Matrix4.fromTranslation(Cartesian3.negate(geoRefAtLocal, new Cartesian3()));

  // @ts-ignore
  const R = Matrix4.fromRotation(Matrix3.fromQuaternion(Quaternion.fromHeadingPitchRoll(hpr)));
  const S = Matrix4.fromScale(scale, new Matrix4());
  const toWorld = Transforms.eastNorthUpToFixedFrame(position);

  const modelMatrix = toWorld;

  Matrix4.multiply(modelMatrix, R, modelMatrix);
  Matrix4.multiply(modelMatrix, S, modelMatrix);
  Matrix4.multiply(modelMatrix, T, modelMatrix);
  Matrix4.multiply(modelMatrix, toLocal, modelMatrix);

  tileset.modelMatrix = modelMatrix;
}

export function georeferenceTileset(tileset, geolocation) {
  const cartographic = Cartographic.fromDegrees(geolocation.longitude, geolocation.latitude, geolocation.height);
  const position = Ellipsoid.WGS84.cartographicToCartesian(cartographic);
  const hpr = new HeadingPitchRoll(
    CesiumMath.toRadians(geolocation.heading),
    CesiumMath.toRadians(geolocation.pitch),
    CesiumMath.toRadians(geolocation.roll)
  );

  const rotationOffsetCenter = new Cartesian3();

  if (geolocation.localGeoRefX) {
    rotationOffsetCenter.x = geolocation.localGeoRefX;
  }

  if (geolocation.localGeoRefY) {
    rotationOffsetCenter.y = geolocation.localGeoRefY;
  }

  if (geolocation.localGeoRefZ) {
    rotationOffsetCenter.z = geolocation.localGeoRefZ;
  }

  const scale = new Cartesian3(geolocation.scale.x, geolocation.scale.y, geolocation.scale.z);

  if (georeferencedAtECEF(tileset)) {
    updateModelMatrixOfTilesetDefinedAtECEF(tileset, position, rotationOffsetCenter, hpr, scale);
  } else {
    updateModelMatrixOfTilesetDefinedAtLocal(tileset, position, rotationOffsetCenter, hpr, scale);
  }
}

export default {
  georeferencedAtECEF,
  updateModelMatrixOfTilesetDefinedAtLocal,
  updateModelMatrixOfTilesetDefinedAtECEF,
  georeferenceTileset,
};