//This file is automatically rebuilt by the Cesium build process.
export default "vec4 czm_screenToEyeCoordinates(vec4 screenCoordinate)\n\
{\n\
float x = 2.0 * screenCoordinate.x - 1.0;\n\
float y = 2.0 * screenCoordinate.y - 1.0;\n\
float z = (screenCoordinate.z - czm_viewportTransformation[3][2]) / czm_viewportTransformation[2][2];\n\
vec4 q = vec4(x, y, z, 1.0);\n\
q /= screenCoordinate.w;\n\
if (!(czm_inverseProjection == mat4(0.0)))\n\
{\n\
q = czm_inverseProjection * q;\n\
}\n\
else\n\
{\n\
float top = czm_frustumPlanes.x;\n\
float bottom = czm_frustumPlanes.y;\n\
float left = czm_frustumPlanes.z;\n\
float right = czm_frustumPlanes.w;\n\
float near = czm_currentFrustum.x;\n\
float far = czm_currentFrustum.y;\n\
q.x = (q.x * (right - left) + left + right) * 0.5;\n\
q.y = (q.y * (top - bottom) + bottom + top) * 0.5;\n\
q.z = (q.z * (near - far) - near - far) * 0.5;\n\
q.w = 1.0;\n\
}\n\
return q;\n\
}\n\
vec4 czm_windowToEyeCoordinates(vec4 fragmentCoordinate)\n\
{\n\
vec2 screenCoordXY = (fragmentCoordinate.xy - czm_viewport.xy) / czm_viewport.zw;\n\
return czm_screenToEyeCoordinates(vec4(screenCoordXY, fragmentCoordinate.zw));\n\
}\n\
vec4 czm_screenToEyeCoordinates(vec2 screenCoordinateXY, float depthOrLogDepth)\n\
{\n\
#if defined(LOG_DEPTH) || defined(LOG_DEPTH_READ_ONLY)\n\
float near = czm_currentFrustum.x;\n\
float far = czm_currentFrustum.y;\n\
float log2Depth = depthOrLogDepth * czm_log2FarDepthFromNearPlusOne;\n\
float depthFromNear = pow(2.0, log2Depth) - 1.0;\n\
float depthFromCamera = depthFromNear + near;\n\
vec4 screenCoord = vec4(screenCoordinateXY, far * (1.0 - near / depthFromCamera) / (far - near), 1.0);\n\
vec4 eyeCoordinate = czm_screenToEyeCoordinates(screenCoord);\n\
eyeCoordinate.w = 1.0 / depthFromCamera;\n\
return eyeCoordinate;\n\
#else\n\
vec4 screenCoord = vec4(screenCoordinateXY, depthOrLogDepth, 1.0);\n\
vec4 eyeCoordinate = czm_screenToEyeCoordinates(screenCoord);\n\
#endif\n\
return eyeCoordinate;\n\
}\n\
vec4 czm_windowToEyeCoordinates(vec2 fragmentCoordinateXY, float depthOrLogDepth)\n\
{\n\
vec2 screenCoordXY = (fragmentCoordinateXY.xy - czm_viewport.xy) / czm_viewport.zw;\n\
return czm_screenToEyeCoordinates(screenCoordXY, depthOrLogDepth);\n\
}\n\
";
