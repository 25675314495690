//This file is automatically rebuilt by the Cesium build process.
export default "vec4 czm_translateRelativeToEye(vec3 high, vec3 low)\n\
{\n\
vec3 highDifference = high - czm_encodedCameraPositionMCHigh;\n\
if (length(highDifference) == 0.0) {\n\
highDifference = vec3(0);\n\
}\n\
vec3 lowDifference = low - czm_encodedCameraPositionMCLow;\n\
return vec4(highDifference + lowDifference, 1.0);\n\
}\n\
";
