/* eslint-disable */
// q@ts-nocheck

import { Cartesian2 } from "../../../Core/cesium/Source/Cesium.js";
import { Cartesian3 } from "../../../Core/cesium/Source/Cesium.js";
import { Check } from "../../../Core/cesium/Source/Cesium.js";
import { defaultValue } from "../../../Core/cesium/Source/Cesium.js";
import { defined } from "../../../Core/cesium/Source/Cesium.js";
import { destroyObject } from "../../../Core/cesium/Source/Cesium.js";
import { Event } from "../../../Core/cesium/Source/Cesium.js";
import { HeadingPitchRoll } from "../../../Core/cesium/Source/Cesium.js";
import { Math as CesiumMath } from "../../../Core/cesium/Source/Cesium.js";
import { Matrix3 } from "../../../Core/cesium/Source/Cesium.js";
import { Matrix4 } from "../../../Core/cesium/Source/Cesium.js";
import { Quaternion } from "../../../Core/cesium/Source/Cesium.js";
import { PrimitiveCollection } from "../../../Core/cesium/Source/Cesium.js";
import { ScreenSpaceEventHandler } from "../../../Core/cesium/Source/Cesium.js";
import { ScreenSpaceEventType } from "../../../Core/cesium/Source/Cesium.js";
import { Transforms } from "../../../Core/cesium/Source/Cesium.js";
import { SceneTransforms } from "../../../Core/cesium/Source/Cesium.js";
import { knockout } from "../../../Core/cesium/Source/Cesium.js";
import getWidgetOrigin from "../getWidgetOrigin.js";
import RotationEditor from "./RotationEditor.js";
import ScaleEditor from "./ScaleEditor.js";
import TranslationEditor from "./TranslationEditor.js";

var widgetPosition = new Cartesian3();
var screenPosition = new Cartesian2();

var noScale = new Cartesian3(1.0, 1.0, 1.0);
var transformScratch = new Matrix4();
var vectorScratch = new Cartesian3();
var scaleScratch = new Cartesian3();
var oldPositionScratch = new Cartesian3();
var oldHeadingPitchRollScratch = new HeadingPitchRoll();

var EditorMode = {
  TRANSLATION: "translation",
  ROTATION: "rotation",
  SCALE: "scale"
};

var setHprQuaternion = new Quaternion();
var setHprQuaternion2 = new Quaternion();
var setHprTranslation = new Cartesian3();
var setHprScale = new Cartesian3();
var setHprCenter = new Cartesian3();
var setHprTransform = new Matrix4();
var setHprRotation = new Matrix3();

/**
 * Creates an interactive transform editor
 * @alias TransformPrimitiveEditorViewModel
 * @ionsdk
 * @constructor
 *
 * @param {Object} options An object with the following properties
 * @param {Scene} options.scene The scene
 * @param {Matrix4} options.transform The transform of the primitive that needs positioning
 * @param {BoundingSphere} options.boundingSphere The bounding sphere of the primitive that needs positioning
 * @param {Cartesian3} [options.originOffset] A offset vector (in local coordinates) from the origin as defined by the transform translation.
 */
function TransformPrimitiveEditorViewModel(options) {
  options = defaultValue(options, defaultValue.EMPTY_OBJECT);

  //>>includeStart('debug', pragmas.debug);
  Check.defined("options.scene", options.scene);
  Check.defined("options.transform", options.transform);
  Check.defined("options.boundingSphere", options.boundingSphere);
  //>>includeEnd('debug');

  var scene = options.scene;
  var transform = options.transform;
  var boundingSphere = options.boundingSphere.clone();

  var originOffset = defaultValue(options.originOffset, Cartesian3.ZERO);

  var position = Matrix4.getTranslation(transform, new Cartesian3());
  var headingPitchRoll = Transforms.fixedFrameToHeadingPitchRoll(
    transform,
    scene.mapProjection.ellipsoid,
    undefined,
    new HeadingPitchRoll()
  );
  var scale = Matrix4.getScale(transform, new Cartesian3());

  /*
  if (Cartesian3.equalsEpsilon(position, Cartesian3.ZERO, CesiumMath.EPSILON10)) {
      position = Cartesian3.fromDegrees(0, 0, 0, scene.mapProjection.ellipsoid, position);
      transform = Matrix4.setTranslation(transform, position, transform);
      this.setHeadingPitchRoll(transform, headingPitchRoll);
  }
  */

  var nonUniformScaling = true;
  if (
    CesiumMath.equalsEpsilon(scale.x, scale.y, CesiumMath.EPSILON10) &&
    CesiumMath.equalsEpsilon(scale.x, scale.z, CesiumMath.EPSILON10)
  ) {
    nonUniformScaling = false;
    scale.y = scale.x;
    scale.z = scale.x;
  }

  var initialRadius = boundingSphere.radius / Cartesian3.maximumComponent(scale);

  /**
   * Gets and sets the selected interactive mode.
   * @type {EditorMode}
   */
  this.editorMode = undefined;
  var editorMode = knockout.observable();
  knockout.defineProperty(this, "editorMode", {
    get: function () {
      return editorMode();
    },
    set: function (value) {
      editorMode(value);
      if (defined(this._activeEditor)) {
        this._activeEditor.active = false;
      }
      var activeEditor;
      if (value === EditorMode.ROTATION) {
        activeEditor = this._rotationEditor;
      } else if (value === EditorMode.TRANSLATION) {
        activeEditor = this._translationEditor;
      } else if (value === EditorMode.SCALE) {
        activeEditor = this._scaleEditor;
      }
      activeEditor.update();
      activeEditor.active = true;
      this._activeEditor = activeEditor;
    }
  });

  /**
   * Gets and sets whether non-uniform scaling is enabled
   * @type {Boolean}
   */
  this.enableNonUniformScaling = nonUniformScaling;
  var enableNonUniformScaling = knockout.observable(this.enableNonUniformScaling);
  knockout.defineProperty(this, "enableNonUniformScaling", {
    get: function () {
      return enableNonUniformScaling();
    },
    set: function (value) {
      if (value === enableNonUniformScaling()) {
        return;
      }
      enableNonUniformScaling(value);
      if (!value) {
        this.scale = new Cartesian3(scale.x, scale.x, scale.x);
        if (scene.requestRenderMode) {
          scene.requestRender();
        }
      }
    }
  });

  /**
   * Gets and sets the position
   * @type {Cartesian3}
   */
  this.position = position;
  var positionObservable = knockout.observable(this.position);
  knockout.defineProperty(this, "position", {
    get: function () {
      return positionObservable();
    },
    set: function (value) {
      if (Cartesian3.equals(value, this.position)) {
        return;
      }

      const oldPosition = Matrix4.getTranslation(this._transform, oldPositionScratch);

      var position = Cartesian3.clone(value, this.position);
      positionObservable(position);
      var transform = this._transform;
      transform = Matrix4.setTranslation(transform, position, transform);
      this.setHeadingPitchRoll(transform, this.headingPitchRoll);
      if (scene.requestRenderMode) {
        scene.requestRender();
      }

      this.positionChanged.raiseEvent(value, oldPosition);
    }
  });

  /**
   * Gets and sets the heading pitch roll
   * @type {HeadingPitchRoll}
   */
  this.headingPitchRoll = headingPitchRoll;
  var headingPitchRollObservable = knockout.observable(this.headingPitchRoll);
  knockout.defineProperty(this, "headingPitchRoll", {
    get: function () {
      return headingPitchRollObservable();
    },
    set: function (value) {
      if (HeadingPitchRoll.equals(value, this.headingPitchRoll)) {
        return;
      }

      const oldHpr = HeadingPitchRoll.clone(this.headingPitchRoll, oldHeadingPitchRollScratch);

      var hpr = HeadingPitchRoll.clone(value, this.headingPitchRoll);
      headingPitchRollObservable(hpr);
      this.setHeadingPitchRoll(this._transform, hpr);
      if (scene.requestRenderMode) {
        scene.requestRender();
      }

      this.headingPitchRollChanged.raiseEvent(value, oldHpr);
    }
  });

  /**
   * Gets and sets the scale
   * @type {Cartesian3}
   */
  this.scale = scale;
  var scaleObservable = knockout.observable(this.scale);
  knockout.defineProperty(this, "scale", {
    get: function () {
      return scaleObservable();
    },
    set: function (value) {
      if (Cartesian3.equals(value, this.scale)) {
        return;
      }
      var scale = Cartesian3.clone(value, this.scale);
      scaleObservable(scale);
      Matrix4.setScale(this._transform, scale, this._transform);
      this._translationEditor.update(); //applies the scale to the editing primitives
      this._rotationEditor.update();
      if (scene.requestRenderMode) {
        scene.requestRender();
      }

      this.scaleChanged.raiseEvent(value);
    }
  });

  /**
   * Gets and sets whether the menu is expanded
   * @type {Boolean}
   */
  this.menuExpanded = false;

  /**
   * Gets the x screen coordinate of the widget menu
   * @type {String}
   * @readonly
   */
  this.left = "0";

  /**
   * Gets the y screen coordinate of the widget menu
   * @type {String}
   * @readonly
   */
  this.top = "0";

  /**
   * Gets whether the widget is active.  Use the activate and deactivate functions to set this value.
   * @type {Boolean}
   * @readonly
   */
  this.active = false;

  knockout.track(this, ["menuExpanded", "left", "top", "active"]);

  const primitiveCollection = new PrimitiveCollection();

  primitiveCollection.id = "TransformPrimitiveEditorViewModel-PrimitiveCollection";

  scene.primitives.add(primitiveCollection);

  var that = this;
  this._rotationEditor = new RotationEditor({
    scene: scene,
    primitiveCollection: primitiveCollection,
    transform: transform,
    radius: initialRadius,
    originOffset: originOffset,
    setPosition: function (value) {
      that.position = value;
    },
    setHeadingPitchRoll: function (value) {
      that.headingPitchRoll = value;
    }
  });
  this._translationEditor = new TranslationEditor({
    scene: scene,
    primitiveCollection: primitiveCollection,
    transform: transform,
    radius: initialRadius,
    originOffset: originOffset,
    setPosition: function (value) {
      that.position = value;
    }
  });
  this._scaleEditor = new ScaleEditor({
    scene: scene,
    primitiveCollection: primitiveCollection,
    transform: transform,
    enableNonUniformScaling: enableNonUniformScaling,
    radius: initialRadius,
    originOffset: originOffset,
    setScale: function (value) {
      that.scale = value;
    },
    setPosition: function (value) {
      that.position = value;
    }
  });

  this._sseh = new ScreenSpaceEventHandler(scene.canvas);
  this._scene = scene;
  this._transform = transform;
  this._boundingSphere = boundingSphere;
  this._active = false;
  this._activeEditor = undefined;
  this._originOffset = originOffset;

  this.position = position;
  this.headingPitchRoll = headingPitchRoll;
  this.scale = scale;

  this._removePostUpdateEvent = this._scene.preUpdate.addEventListener(
    TransformPrimitiveEditorViewModel.prototype._update,
    this
  );

  this.positionChanged = new Event();
  this.headingPitchRollChanged = new Event();
  this.scaleChanged = new Event();
  this._activeTranslationAndRotationMode = false;
}

Object.defineProperties(TransformPrimitiveEditorViewModel.prototype, {
  /**
   * Gets and sets the offset of the transform editor UI components from the origin as defined by the transform
   * @type {Cartesian3}
   * @memberof TransformPrimitiveEditorViewModel
   */
  originOffset: {
    get: function () {
      return this._originOffset;
    },
    set: function (value) {
      //>>includeStart('debug', pragmas.debug);
      Check.defined("value", value);
      //>>includeEnd('debug');
      this._originOffset = value;

      this._translationEditor.originOffset = value;
      this._rotationEditor.originOffset = value;
      this._scaleEditor.originOffset = value;
    }
  },
  activeTranslationAndRotation: {
    get: function () {
      return this._activeTranslationAndRotationMode;
    }
  }
});

/**
 * Sets the originOffset based on the Cartesian3 position in world coordinates
 * @param {Matrix4} transform
 * @param {HeadingPitchRoll} headingPitchRoll
 */
TransformPrimitiveEditorViewModel.prototype.setHeadingPitchRoll = function (transform, headingPitchRoll) {
  //>>includeStart('debug', pragmas.debug);
  Check.defined("transform", transform);
  Check.defined("headingPitchRoll", headingPitchRoll);
  //>>includeEnd('debug');

  var rotationQuaternion = Quaternion.fromHeadingPitchRoll(headingPitchRoll, setHprQuaternion);
  var translation = Matrix4.getTranslation(transform, setHprTranslation);
  var scale = Matrix4.getScale(transform, setHprScale);
  var center = Matrix4.multiplyByPoint(transform, Cartesian3.ZERO, setHprCenter);
  var backTransform = Transforms.eastNorthUpToFixedFrame(center, undefined, setHprTransform);

  var rotationFixed = Matrix4.getMatrix3(backTransform, setHprRotation);
  var quaternionFixed = Quaternion.fromRotationMatrix(rotationFixed, setHprQuaternion2);
  var rotation = Quaternion.multiply(quaternionFixed, rotationQuaternion, rotationFixed);

  return Matrix4.fromTranslationQuaternionRotationScale(translation, rotation, scale, transform);
};

/**
 * Sets the originOffset based on the Cartesian3 position in world coordinates
 * @param {Cartesian3} position
 */
TransformPrimitiveEditorViewModel.prototype.setOriginPosition = function (position) {
  //>>includeStart('debug', pragmas.debug);
  Check.defined("position", position);
  //>>includeEnd('debug');
  var transform = Matrix4.setScale(this._transform, noScale, transformScratch);
  var worldToLocalCoordinates = Matrix4.inverseTransformation(transform, transform);
  var point = Matrix4.multiplyByPoint(worldToLocalCoordinates, position, vectorScratch);
  var offset = Cartesian3.divideComponents(point, Matrix4.getScale(this._transform, scaleScratch), point);

  this.originOffset = offset;
};

/**
 * Activates the widget by showing the primitives and enabling mouse handlers
 */
TransformPrimitiveEditorViewModel.prototype.activate = function () {
  window.dispatchEvent(new CustomEvent("transformEditorToggled", { detail: { active: true } }));
  var sseh = this._sseh;
  var scene = this._scene;

  sseh.setInputAction(this._leftDown.bind(this), ScreenSpaceEventType.LEFT_DOWN);
  sseh.setInputAction(this._leftUp.bind(this), ScreenSpaceEventType.LEFT_UP);
  sseh.setInputAction(this._mouseMove.bind(this), ScreenSpaceEventType.MOUSE_MOVE);
  this.active = true;
  if (defined(this._activeEditor)) {
    this._activeEditor.active = true;
  } else {
    this.setModeTranslation();
  }
  if (scene.requestRenderMode) {
    scene.requestRender();
  }
};

/**
 * Deactivates the widget by disabling mouse handlers and hiding the primitives
 */
TransformPrimitiveEditorViewModel.prototype.deactivate = function () {
  window.dispatchEvent(new CustomEvent("transformEditorToggled", { detail: { active: false } }));
  var sseh = this._sseh;
  var scene = this._scene;

  sseh.removeInputAction(ScreenSpaceEventType.LEFT_DOWN);
  sseh.removeInputAction(ScreenSpaceEventType.LEFT_UP);
  sseh.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE);

  this.active = false;
  if (defined(this._activeEditor)) {
    this._activeEditor.active = false;
  }
  if (scene.requestRenderMode) {
    scene.requestRender();
  }

  if (this._activeTranslationAndRotationMode) {
    this._translationEditor.active = false;
    this._rotationEditor.active = false;
    this._activeTranslationAndRotationMode = false;
  }
};

/**
 * Expands the widget menu
 */
TransformPrimitiveEditorViewModel.prototype.expandMenu = function () {
  this.menuExpanded = true;
};

/**
 * Activates the translation interactive mode
 */
TransformPrimitiveEditorViewModel.prototype.setModeTranslation = function () {
  this.editorMode = EditorMode.TRANSLATION;
  this.menuExpanded = false;
};

/**
 * Activates the rotation interactive mode
 */
TransformPrimitiveEditorViewModel.prototype.setModeRotation = function () {
  this.editorMode = EditorMode.ROTATION;
  this.menuExpanded = false;
};

/**
 * Activates the scale interactive mode
 */
TransformPrimitiveEditorViewModel.prototype.setModeScale = function () {
  this.editorMode = EditorMode.SCALE;
  this.menuExpanded = false;
};

/**
 * Toggles whether non-uniform scaling is enabled
 */
TransformPrimitiveEditorViewModel.prototype.toggleNonUniformScaling = function () {
  this.enableNonUniformScaling = !this.enableNonUniformScaling;
};

/**
 * @private
 */
TransformPrimitiveEditorViewModel.prototype._leftDown = function (click) {
  if (this._activeTranslationAndRotationMode) {
    this._rotationEditor.handleLeftDown(click.position);
    this._translationEditor.handleLeftDown(click.position);
  } else {
    this._activeEditor.handleLeftDown(click.position);
  }

  var scene = this._scene;
  if (scene.requestRenderMode) {
    scene.requestRender();
  }
};

/**
 * @private
 */
TransformPrimitiveEditorViewModel.prototype._mouseMove = function (movement) {
  if (this._activeTranslationAndRotationMode) {
    this._rotationEditor.handleMouseMove(movement.endPosition);
    this._translationEditor.handleMouseMove(movement.endPosition);
  } else {
    this._activeEditor.handleMouseMove(movement.endPosition);
  }

  var scene = this._scene;
  if (scene.requestRenderMode) {
    scene.requestRender();
  }
};

/**
 * @private
 */
TransformPrimitiveEditorViewModel.prototype._leftUp = function (click) {
  this.menuExpanded = false;

  if (this._activeTranslationAndRotationMode) {
    this._rotationEditor.handleLeftUp(click.position);
    this._translationEditor.handleLeftUp(click.position);
  } else {
    this._activeEditor.handleLeftUp(click.position);
  }

  var scene = this._scene;
  if (scene.requestRenderMode) {
    scene.requestRender();
  }
};

/**
 * Updates the active editor
 * @private
 */
TransformPrimitiveEditorViewModel.prototype._update = function () {
  if (!this.active && !this._activeTranslationAndRotationMode) {
    return;
  }

  if (this._activeTranslationAndRotationMode) {
    this._translationEditor.update();
    this._rotationEditor.update();
  } else {
    this._activeEditor.update();
  }

  var scene = this._scene;
  var position = getWidgetOrigin(this._transform, this._originOffset, widgetPosition);
  var newPos = SceneTransforms.wgs84ToWindowCoordinates(scene, position, screenPosition);
  if (defined(newPos)) {
    this.left = Math.floor(newPos.x - 13) + "px";
    this.top = Math.floor(newPos.y) + "px";
  }
};

/**
 * @returns {Boolean} true if the object has been destroyed, false otherwise.
 */
TransformPrimitiveEditorViewModel.prototype.isDestroyed = function () {
  return false;
};

/**
 * Destroys the view model.
 */
TransformPrimitiveEditorViewModel.prototype.destroy = function () {
  this.deactivate();
  this._sseh.destroy();
  this._rotationEditor.destroy();
  this._translationEditor.destroy();
  this._scaleEditor.destroy();
  this._removePostUpdateEvent();
  destroyObject(this);
};

TransformPrimitiveEditorViewModel.prototype.activateTranslationAndRotationMode = function () {
  var sseh = this._sseh;
  var scene = this._scene;

  sseh.setInputAction(this._leftDown.bind(this), ScreenSpaceEventType.LEFT_DOWN);
  sseh.setInputAction(this._leftUp.bind(this), ScreenSpaceEventType.LEFT_UP);
  sseh.setInputAction(this._mouseMove.bind(this), ScreenSpaceEventType.MOUSE_MOVE);

  this._activeTranslationAndRotationMode = true;
  this._translationEditor.active = true;
  this._rotationEditor.active = true;

  this._translationEditor.update();
  this._rotationEditor.update();

  if (scene.requestRenderMode) {
    scene.requestRender();
  }

  // this._rotationEditor.hideXY();
};

TransformPrimitiveEditorViewModel.EditorMode = EditorMode;
export default TransformPrimitiveEditorViewModel;
