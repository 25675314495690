//This file is automatically rebuilt by the Cesium build process.
export default "vec3 czm_applyHSBShift(vec3 rgb, vec3 hsbShift, bool ignoreBlackPixels) {\n\
vec3 hsb = czm_RGBToHSB(rgb);\n\
hsb.x += hsbShift.x;\n\
hsb.y = clamp(hsb.y + hsbShift.y, 0.0, 1.0);\n\
if (ignoreBlackPixels) {\n\
hsb.z = hsb.z > czm_epsilon7 ? hsb.z + hsbShift.z : 0.0;\n\
} else {\n\
hsb.z = hsb.z + hsbShift.z;\n\
}\n\
hsb.z = clamp(hsb.z, 0.0, 1.0);\n\
return czm_HSBToRGB(hsb);\n\
}\n\
";
