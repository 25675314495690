//This file is automatically rebuilt by the Cesium build process.
export default "in vec3 position3DHigh;\n\
in vec3 position3DLow;\n\
in float batchId;\n\
#ifdef EXTRUDED_GEOMETRY\n\
in vec3 extrudeDirection;\n\
uniform float u_globeMinimumAltitude;\n\
#endif // EXTRUDED_GEOMETRY\n\
#ifdef PER_INSTANCE_COLOR\n\
out vec4 v_color;\n\
#endif // PER_INSTANCE_COLOR\n\
#ifdef TEXTURE_COORDINATES\n\
#ifdef SPHERICAL\n\
out vec4 v_sphericalExtents;\n\
#else // SPHERICAL\n\
out vec2 v_inversePlaneExtents;\n\
out vec4 v_westPlane;\n\
out vec4 v_southPlane;\n\
#endif // SPHERICAL\n\
out vec3 v_uvMinAndSphericalLongitudeRotation;\n\
out vec3 v_uMaxAndInverseDistance;\n\
out vec3 v_vMaxAndInverseDistance;\n\
#endif // TEXTURE_COORDINATES\n\
void main()\n\
{\n\
vec4 position = czm_computePosition();\n\
#ifdef EXTRUDED_GEOMETRY\n\
float delta = min(u_globeMinimumAltitude, czm_geometricToleranceOverMeter * length(position.xyz));\n\
delta *= czm_sceneMode == czm_sceneMode3D ? 1.0 : 0.0;\n\
position = position + vec4(extrudeDirection * delta, 0.0);\n\
#endif\n\
#ifdef TEXTURE_COORDINATES\n\
#ifdef SPHERICAL\n\
v_sphericalExtents = czm_batchTable_sphericalExtents(batchId);\n\
v_uvMinAndSphericalLongitudeRotation.z = czm_batchTable_longitudeRotation(batchId);\n\
#else // SPHERICAL\n\
#ifdef COLUMBUS_VIEW_2D\n\
vec4 planes2D_high = czm_batchTable_planes2D_HIGH(batchId);\n\
vec4 planes2D_low = czm_batchTable_planes2D_LOW(batchId);\n\
vec2 idlSplitNewPlaneHiLow = vec2(EAST_MOST_X_HIGH - (WEST_MOST_X_HIGH - planes2D_high.w), EAST_MOST_X_LOW - (WEST_MOST_X_LOW - planes2D_low.w));\n\
bool idlSplit = planes2D_high.x > planes2D_high.w && position3DLow.y > 0.0;\n\
planes2D_high.w = czm_branchFreeTernary(idlSplit, idlSplitNewPlaneHiLow.x, planes2D_high.w);\n\
planes2D_low.w = czm_branchFreeTernary(idlSplit, idlSplitNewPlaneHiLow.y, planes2D_low.w);\n\
idlSplit = planes2D_high.x > planes2D_high.w && position3DLow.y < 0.0;\n\
idlSplitNewPlaneHiLow = vec2(WEST_MOST_X_HIGH - (EAST_MOST_X_HIGH - planes2D_high.x), WEST_MOST_X_LOW - (EAST_MOST_X_LOW - planes2D_low.x));\n\
planes2D_high.x = czm_branchFreeTernary(idlSplit, idlSplitNewPlaneHiLow.x, planes2D_high.x);\n\
planes2D_low.x = czm_branchFreeTernary(idlSplit, idlSplitNewPlaneHiLow.y, planes2D_low.x);\n\
vec3 southWestCorner = (czm_modelViewRelativeToEye * czm_translateRelativeToEye(vec3(0.0, planes2D_high.xy), vec3(0.0, planes2D_low.xy))).xyz;\n\
vec3 northWestCorner = (czm_modelViewRelativeToEye * czm_translateRelativeToEye(vec3(0.0, planes2D_high.x, planes2D_high.z), vec3(0.0, planes2D_low.x, planes2D_low.z))).xyz;\n\
vec3 southEastCorner = (czm_modelViewRelativeToEye * czm_translateRelativeToEye(vec3(0.0, planes2D_high.w, planes2D_high.y), vec3(0.0, planes2D_low.w, planes2D_low.y))).xyz;\n\
#else // COLUMBUS_VIEW_2D\n\
vec3 southWestCorner = (czm_modelViewRelativeToEye * czm_translateRelativeToEye(czm_batchTable_southWest_HIGH(batchId), czm_batchTable_southWest_LOW(batchId))).xyz;\n\
vec3 northWestCorner = czm_normal * czm_batchTable_northward(batchId) + southWestCorner;\n\
vec3 southEastCorner = czm_normal * czm_batchTable_eastward(batchId) + southWestCorner;\n\
#endif // COLUMBUS_VIEW_2D\n\
vec3 eastWard = southEastCorner - southWestCorner;\n\
float eastExtent = length(eastWard);\n\
eastWard /= eastExtent;\n\
vec3 northWard = northWestCorner - southWestCorner;\n\
float northExtent = length(northWard);\n\
northWard /= northExtent;\n\
v_westPlane = vec4(eastWard, -dot(eastWard, southWestCorner));\n\
v_southPlane = vec4(northWard, -dot(northWard, southWestCorner));\n\
v_inversePlaneExtents = vec2(1.0 / eastExtent, 1.0 / northExtent);\n\
#endif // SPHERICAL\n\
vec4 uvMinAndExtents = czm_batchTable_uvMinAndExtents(batchId);\n\
vec4 uMaxVmax = czm_batchTable_uMaxVmax(batchId);\n\
v_uMaxAndInverseDistance = vec3(uMaxVmax.xy, uvMinAndExtents.z);\n\
v_vMaxAndInverseDistance = vec3(uMaxVmax.zw, uvMinAndExtents.w);\n\
v_uvMinAndSphericalLongitudeRotation.xy = uvMinAndExtents.xy;\n\
#endif // TEXTURE_COORDINATES\n\
#ifdef PER_INSTANCE_COLOR\n\
v_color = czm_batchTable_color(batchId);\n\
#endif\n\
gl_Position = czm_depthClamp(czm_modelViewProjectionRelativeToEye * position);\n\
}\n\
";
