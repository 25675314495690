import {
    Cartesian3,
    Color,
    destroyObject,
    HeadingPitchRoll,
    HorizontalOrigin,
    LabelCollection,
    LabelStyle,
    Matrix4,
    Transforms,
    VerticalOrigin,
} from "../../Core/cesium/Source/Cesium.js";
import PolylinePrimitive from "../Scene/PolylinePrimitive.js";
import { EntityTypes, getLeftOrRightPointInfo, ScreenDirections } from "./common";
import BoxPrimitive from "./BoxPrimitive";
import ToolEntity from "./ToolEntity";
import MeasureUnits from "./Measure/MeasureUnits.js";
import DistanceUnits from "./Measure/DistanceUnits.js";

const BoxIdPrefix = "box";

export default class Box extends ToolEntity {
    constructor(options) {
        super({
            id: options.id,
            type: EntityTypes.BOX,
            name: options.name,
            viewer: options.viewer,
            snapToTerrain: options.snapToTerrain,
            customData: options.customData,
            orderIndex: options.orderIndex,
            locked: options.locked,
            visible: options.visible,
            visibleFrom: options.visibleFrom,
            visibleTo: options.visibleTo
        });

        this.color = options;
        this.isBox = options.isBox;
        this.usage = options.usage;
        this.position = options.position;

        const primitive = new BoxPrimitive({
            id: `${BoxIdPrefix}:${this.id}`,
            modelMatrix: options.modelMatrix ? options.modelMatrix : Transforms.headingPitchRollToFixedFrame(options.position, new HeadingPitchRoll(options.heading, 0, 0)),
            color: options.color,
            selectable: true,
            dimensions: options.dimensions,
            _show: options.visible,
        });

        this._primitive = primitive;

        const primitives = this.viewer.scene.primitives;

        primitives.add(primitive);

        this._xAxis = new PolylinePrimitive({
            id: "xAxis",
            color: Color.RED,
            show: options.visible && options.dimensions.showDimensions,
            ignoreDepthTest: true,
        });

        this._yAxis = new PolylinePrimitive({
            id: "yAxis",
            color: Color.GREEN,
            show: options.visible && options.dimensions.showDimensions,
            ignoreDepthTest: true,
        });

        this._zAxis = new PolylinePrimitive({
            id: "zAxis",
            color: Color.BLUE,
            show: options.visible && options.dimensions.showDimensions,
            ignoreDepthTest: true,
        });

        if (!primitives.contains(this._xAxis)) primitives.add(this._xAxis);
        if (!primitives.contains(this._yAxis)) primitives.add(this._yAxis);
        if (!primitives.contains(this._zAxis)) primitives.add(this._zAxis);

        this._labelCollection = new LabelCollection({ show: options.visible });

        primitives.add(this._labelCollection);

        const labelOptions = {
            show: options.visible && options.dimensions.showDimensions,
            font: "20px Helvetica",
            style: LabelStyle.FILL_AND_OUTLINE,
            outlineColor: Color.BLACK,
            outlineWidth: 2,
            scale: 0.8,
            fillColor: Color.fromCssColorString("rgba(255,255,255,1)"),
            horizontalOrigin: HorizontalOrigin.CENTER,
            verticalOrigin: VerticalOrigin.TOP,
            showBackground: true,
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
        };

        this._widthLabel = this._labelCollection.add({ ...labelOptions, backgroundColor: Color.RED });
        this._depthLabel = this._labelCollection.add({
            ...labelOptions,
            backgroundColor: Color.GREEN
        });
        this._heightLabel = this._labelCollection.add({ ...labelOptions, backgroundColor: Color.BLUE });

        this.initialized = true;

        this.updateMeasurementLabels();
    }

    get depth() {
        return this._primitive.depth;
    }

    set depth(val) {
        if (this._primitive) {
            this._primitive.depth = val;
            this.updateMeasurementLabels();
        }
    }

    updateWidth(val) {
        if (this._primitive) {
            this._primitive.updateWidth(val);
        }
    }

    updateHeight(val) {
        if (this._primitive) {
            this._primitive.updateHeight(val);
        }
    }

    updateLength(val) {
        if (this._primitive) {
            this._primitive.updateLength(val);
        }
    }

    get modelMatrix() {
        return this._primitive?.modelMatrix ?? Matrix4.IDENTITY;
    }

    get boundingSphere() {
        return this._primitive?.boundingSphere;
    }

    forceUpdate() {
        this._primitive.forceUpdate();
        this.updateMeasurementLabels();
    }

    get rightMostTopPlanePoint() {
        if (!this.viewer) {
            return undefined;
        }

        const scene = this.viewer.scene;
        const modelMatrix = this.modelMatrix;

        const groundPoints = this.groundPoints;

        const points = groundPoints.map((position) => new Cartesian3(position.x, position.y, this.height));

        return getLeftOrRightPointInfo(ScreenDirections.RIGHT, points, modelMatrix, scene)?.pointWC;
    }

    get groundPoints() {
        const dimensions = this._primitive.dimensions;
        const width = dimensions.x;
        const height = dimensions.y;

        const groundPoints = [
            new Cartesian3(-width / 2, -height / 2, 0),
            new Cartesian3(width / 2, -height / 2, 0),
            new Cartesian3(width / 2, height / 2, 0),
            new Cartesian3(-width / 2, height / 2, 0)
        ];

        return groundPoints;
    }

    updateMeasurementLabels() {
        const dimensions = this._primitive.dimensions;
        const decimal = 1;

        this._widthLabel.text = MeasureUnits.distanceToString(
          dimensions.x,
          localStorage.getItem('measurementUnits') || DistanceUnits.FEET,
        );
        this._widthLabel.position = this._primitive.getCenterOfWidth();

        this._depthLabel.text = MeasureUnits.distanceToString(
          dimensions.y,
          localStorage.getItem('measurementUnits') || DistanceUnits.FEET,
        );
        this._depthLabel.position = this._primitive.getCenterOfDepth();

        this._heightLabel.text = MeasureUnits.distanceToString(
          dimensions.z,
          localStorage.getItem('measurementUnits') || DistanceUnits.FEET,
        );
        this._heightLabel.position = this._primitive.getCenterOfHeight();

        this._xAxis.positions = this._primitive.getXAxisPositions();
        this._yAxis.positions = this._primitive.getYAxisPositions();
        this._zAxis.positions = this._primitive.getZAxisPositions();
    }

    get dimensions() {
        return this._primitive.dimensions;
    }

    removeFromCesium(viewer) {
        const primitives = viewer.scene.primitives;

        primitives.remove(this._primitive);
        primitives.remove(this._xAxis);
        primitives.remove(this._yAxis);
        primitives.remove(this._zAxis);

        primitives.remove(this._labelCollection);

        destroyObject(this);
    }
}
